import { axios } from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'
import { CreateDocumentInDocumentSetRequest, createDocumentInDocumentSetResponseSchema } from '@publica/schemas'

import { getAuthHeaders } from '../auth'

export class DocumentSetsClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async createDocumentInDocumentSets(
        documentSetId: string,
        { name, participantId, categories }: CreateDocumentInDocumentSetRequest,
        data: Blob
    ) {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)
        const formData = new FormData()

        // The 'file' has to match what the server expects
        formData.append('file', data)

        if (name !== undefined) {
            formData.append('name', name)
        }

        formData.append('participantId', participantId)

        if (categories !== undefined) {
            for (const category of categories) {
                formData.append('categories[]', category)
            }
        }

        return axios
            .post<unknown>(this.endpoints.documentSets.createDocumentInDocumentSet(documentSetId), formData, {
                headers,
            })
            .then(({ data }) => createDocumentInDocumentSetResponseSchema.parse(data))
    }
}
