import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'

import { useArchiveEmailSetsMutation } from '../../../../../../../data'
import { EmailSet } from '../../../types'

type EmailSetArchiveControlProps = {
    emailSet: EmailSet
}

const emailSetIsArchivable = (emailSet: EmailSet) =>
    !emailSet.isArchived && !emailSet.emails.some(email => email.status !== 'READY')

export const EmailSetArchiveControl: FC<EmailSetArchiveControlProps> = ({ emailSet }) => {
    const { t } = useEmailSetArchiveControlTranslation()
    const [archiveEmailSetsMutation, { loading }] = useArchiveEmailSetsMutation()

    const enabled = useMemo(() => !loading && emailSetIsArchivable(emailSet), [emailSet, loading])

    const onClick = useAsyncCallback(async () => {
        await archiveEmailSetsMutation({
            variables: {
                ids: [emailSet.id],
            },
        })
    }, [archiveEmailSetsMutation, emailSet.id])

    return (
        <ActionButton disabled={!enabled} onClick={onClick}>
            {t('archive')}
        </ActionButton>
    )
}

const useEmailSetArchiveControlTranslation = createUseTranslation({
    FR: {
        archive: 'Archiver',
    },
    EN: {
        archive: 'Archive',
    },
})
