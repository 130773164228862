import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const legalEntityRepresentationTypeSchema = z.enum(['DELEGATION', 'SELF_REPRESENTATION'])

export type LegalEntityRepresentationType = z.infer<typeof legalEntityRepresentationTypeSchema>

const legalEntityRepresentationTypes: Record<KnownLocale, Record<LegalEntityRepresentationType, string>> = {
    FR: {
        DELEGATION: 'Délégation',
        SELF_REPRESENTATION: 'Autoreprésenté',
    },
    EN: {
        DELEGATION: 'Delegated',
        SELF_REPRESENTATION: 'Self-represented',
    },
}

export const legalEntityRepresentationTypeLookup = new LookupList(legalEntityRepresentationTypes)
