import { Modal } from 'antd'
import { ButtonProps } from 'antd/lib'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ControlledFormWithElement, FormValues } from '@publica/ui-web-utils'

import { ActionButton } from '../ActionButton'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModalFormProps<Result = any, Values extends FormValues = any> = {
    form: ControlledFormWithElement<Result, Values>
    title: string
    okText?: string
    cancelText?: string
    icon: ReactNode
    disabled?: boolean
    buttonSize?: ButtonProps['size']
}

export const ModalForm: FC<ModalFormProps> = ({
    title,
    cancelText,
    okText,
    icon,
    form,
    disabled = false,
    buttonSize = 'middle',
}) => {
    const [open, setOpen] = useState(false)
    const [formSubmitDisabled, setFormSubmitDisabled] = useState(false)

    const { t } = useModalFormTranslation()

    const showForm = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        form.control.reset()
    }, [form])

    const submit = useAsyncCallback(async () => {
        await form.control.submit().then(({ validated }) => {
            if (validated) {
                close()
            }
        })
    }, [close, form.control])

    useEffect(() => {
        form.control.events.on('disable', async event => {
            setFormSubmitDisabled(event.disabled)
        })
    }, [form.control.events])

    const okButtonProps = useMemo(
        (): ButtonProps => ({
            disabled: formSubmitDisabled,
        }),
        [formSubmitDisabled]
    )

    return (
        <>
            <ActionButton size={buttonSize} icon={icon} onClick={showForm} disabled={disabled}>
                {title}
            </ActionButton>
            <Modal
                open={open}
                onCancel={close}
                cancelText={cancelText ?? t('cancel')}
                title={title}
                confirmLoading={form.control.loading}
                okText={okText ?? t('create')}
                onOk={submit}
                destroyOnClose
                okButtonProps={okButtonProps}
            >
                {form.element}
            </Modal>
        </>
    )
}

const useModalFormTranslation = createUseTranslation({
    FR: {},
    EN: {},
})
