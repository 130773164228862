import { z } from 'zod'

import { countryLookup } from '@publica/lookups'

export const addressSchema = z.object({
    address: z.string(),
    city: z.string(),
    postCode: z.string(),
    country: z.enum(countryLookup.keys()),
})

export type Address = z.infer<typeof addressSchema>
