import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { Operation } from '../types'

type EmailSetControlsProps = {
    operation: Operation
}

const useEmailSetControlsTranslation = createUseTranslation({
    EN: {
        generate: 'Generate an email',
    },
    FR: {
        generate: 'Générer un email',
    },
})

export const EmailSetControls: FC<EmailSetControlsProps> = ({ operation }) => {
    const mutable = operationIsOpen(operation)
    const navigate = useNavigate()
    const controlStyles = utils.useControlsStyles()
    const { t } = useEmailSetControlsTranslation()

    const onClick = useCallback(() => {
        navigate('new')
    }, [navigate])

    return (
        <div className={controlStyles.controls}>
            <ActionButton size="middle" onClick={onClick} disabled={!mutable} icon={icons.AddFile}>
                {t('generate')}
            </ActionButton>
        </div>
    )
}
