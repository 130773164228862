import * as Sentry from '@sentry/react'

import { userEvents } from '@publica/ui-common-auth'
import { logger } from '@publica/ui-common-logger'

if (!__DEVSERVER__) {
    logger.info('Activating Sentry')

    Sentry.init({
        dsn: 'https://eff5d7c4679f020e024e5deeeac114b6@o4509016169775104.ingest.de.sentry.io/4509016171544656',
        release: `${__APP__}@${__VERSION__}`,
        integrations: [
            Sentry.captureConsoleIntegration({
                levels: ['error', 'warn'],
            }),
        ],
    })

    userEvents.on('userChanged', ({ user }) => {
        if (user === undefined) {
            logger.info('Clearing Sentry user')
            Sentry.setUser(null)
        } else {
            logger.info(`Setting Sentry user: ${user.id}`)
            Sentry.setUser({
                id: user.id,
                email: user.email,
            })
        }
    })
} else {
    logger.info('Skipping Sentry in devserver')
}
