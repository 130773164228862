import { FC } from '@publica/ui-common-utils'

import { EmailSetControls } from './EmailSetControls'
import { EmailSetTable } from './EmailSetTable'
import { Operation } from './types'

export type EmailsIndexProps = {
    operation: Operation
}

export const EmailsIndex: FC<EmailsIndexProps> = ({ operation }) => {
    return (
        <>
            <EmailSetControls operation={operation} />
            <EmailSetTable operation={operation} />
        </>
    )
}
