import { axios } from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'
import {
    CreateDocumentFileArchiveRequest,
    CreateDocumentFileArchiveResponse,
    createDocumentFileArchiveResponseSchema,
} from '@publica/schemas'

import { getAuthHeaders } from '../auth'
import { Download, createDownloadFromResponse } from './download'

export class DocumentsClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async download(id: string): Promise<Download> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .get<Blob>(this.endpoints.documents.download(id), {
                responseType: 'blob',
                headers,
            })
            .then(createDownloadFromResponse)
    }

    async downloadArchive(id: string): Promise<Download> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .get<Blob>(this.endpoints.documents.downloadArchive(id), {
                responseType: 'blob',
                headers,
            })
            .then(createDownloadFromResponse)
    }

    async createArchive(request: CreateDocumentFileArchiveRequest): Promise<CreateDocumentFileArchiveResponse> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)
        return axios
            .post<Blob>(this.endpoints.documents.createArchive(), request, {
                headers,
            })
            .then(({ data }) => createDocumentFileArchiveResponseSchema.parse(data))
    }
}
