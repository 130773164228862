import { z } from 'zod'

import { emailSchema } from './email'

export const createDocumentFileArchiveRequestSchema = z.object({
    operationId: z.string(),
    documentFileIds: z.array(z.string()).min(1),
    groupBy: z.enum(['participant']).optional(),
})

export type CreateDocumentFileArchiveRequest = z.infer<typeof createDocumentFileArchiveRequestSchema>

export const createDocumentFileArchiveResponseSchema = z.object({
    id: z.string(),
    status: z.enum(['PENDING', 'READY', 'CREATING']),
})
export type CreateDocumentFileArchiveResponse = z.infer<typeof createDocumentFileArchiveResponseSchema>

export const documentSignatorySmsAuthenticationSchema = z.object({
    prefix: z.string(),
    number: z.string(),
})

export const documentSignatorySchema = z.object({
    name: z.string().min(1),
    email: emailSchema,
    order: z.number().optional(),
    index: z.number(),
    smsAuthentication: documentSignatorySmsAuthenticationSchema.optional(),
})

export type DocumentSignatory = z.infer<typeof documentSignatorySchema>

export type SignatorySMSAuthentication = {
    id: string
    prefix: string
    number: string
}

export type ParticipantDocumentSignatory = {
    order?: number
    requiresSMSAuthentication?: boolean
    powerOfAttorney?: {
        name: string
        email: string
        smsAuthentication?: Omit<SignatorySMSAuthentication, 'id'>
    }
}

export const createDocumentInDocumentSetRequestSchema = z.object({
    participantId: z.string(),
    name: z.string(),
    categories: z.array(z.enum(['FINAL', 'DRAFT', 'BANK', 'INFORMATION'])).optional(),
})

export type CreateDocumentInDocumentSetRequest = z.infer<typeof createDocumentInDocumentSetRequestSchema>

export const createDocumentInDocumentSetResponseSchema = z.object({
    id: z.string(),
})

export type CreateDocumentInDocumentSetResponse = z.infer<typeof createDocumentInDocumentSetResponseSchema>
