import { Button, Space } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { DateTime } from 'luxon'
import { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'

import { icons } from '..'
import { ActionButton } from '../ActionButton'
import { DatePicker } from '../DatePicker'
import { SearchIcon } from '../FilterTable'
import { VerticalSpacer } from '../VerticalSpacer'

type DateFilterable<K extends string> = Record<K, DateTime | undefined>

export const filterByDate = <K extends string, F extends DateFilterable<K>>(key: K): ColumnType<F> => ({
    filterDropdown: props => <DateRangeFilter {...props} />,
    filterIcon: filtered => <SearchIcon filtered={filtered} />,
    onFilter: (value, record) => {
        const dateRange = value as unknown as DateRange | undefined
        const { after, before } = dateRange ?? {}

        const recordDate = record[key]

        if (recordDate === undefined) {
            return false
        }

        if (after !== undefined && recordDate < after) {
            return false
        }

        if (before !== undefined && recordDate > before) {
            return false
        }

        return true
    },
})

type DateRangeFilterProps = FilterDropdownProps

export type DateRange = {
    before?: DateTime
    after?: DateTime
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({ clearFilters, confirm, setSelectedKeys, selectedKeys }) => {
    const providedDates = selectedKeys[0] as DateRange | undefined

    const [after, setAfter] = useState<DateTime | undefined>(providedDates?.after)
    const [before, setBefore] = useState<DateTime | undefined>(providedDates?.before)

    const onAfterChange = useCallback((value: DateTime | null) => {
        setAfter(value?.setZone('UTC', { keepLocalTime: true }) ?? undefined)
    }, [])

    const onBeforeChange = useCallback((value: DateTime | null) => {
        setBefore(value?.setZone('UTC', { keepLocalTime: true }) ?? undefined)
    }, [])

    const onReset = useCallback(() => {
        setAfter(undefined)
        setBefore(undefined)
        clearFilters?.()
        confirm({ closeDropdown: true })
    }, [clearFilters, confirm])

    const onSearch = useCallback(() => {
        // This is a workaround since the types are too strict
        setSelectedKeys([{ after, before }] as unknown as React.Key[])
        confirm()
    }, [confirm, after, setSelectedKeys, before])

    const searchEnabled = after !== undefined || before !== undefined

    const { t } = useDateRangeFilterTranslation()
    const styles = useDateRangeFilterStyles()

    return (
        <div className={styles.box}>
            <VerticalSpacer size={20}>
                <div>
                    <div className={styles.firstPicker}>
                        <p>{t('after')}</p>
                        <DatePicker
                            className={styles.datePicker}
                            onChange={onAfterChange}
                            allowClear={true}
                            value={after}
                        />
                    </div>
                    <div>
                        <p>{t('before')}</p>
                        <DatePicker
                            className={styles.datePicker}
                            onChange={onBeforeChange}
                            allowClear={true}
                            value={before}
                        />
                    </div>
                </div>
                <div className={styles.controls}>
                    <Space>
                        <Button onClick={onReset} size="small">
                            {t('reset')}
                        </Button>
                        <ActionButton disabled={!searchEnabled} onClick={onSearch} icon={icons.Search} size="small">
                            {t('search')}
                        </ActionButton>
                    </Space>
                </div>
            </VerticalSpacer>
        </div>
    )
}

const useDateRangeFilterTranslation = createUseTranslation({
    FR: {
        before: 'Avant',
        after: 'Après',
        search: 'Filtrer',
        reset: 'Annuler',
    },
    EN: {
        before: 'Before',
        after: 'After',
        search: 'Filter',
        reset: 'Reset',
    },
})

const useDateRangeFilterStyles = createUseStyles({
    datePicker: {
        width: '100%',
    },
    controls: {
        textAlign: 'right',
    },
    box: {
        padding: 12,
        minWidth: 300,
    },
    firstPicker: {
        marginBottom: 15,
    },
})
