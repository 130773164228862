import { ColumnType, ExpandableConfig, TablePaginationConfig } from 'antd/es/table/interface'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanCreatedAt } from '@publica/ui-common-labels'
import { FC, useAsyncCallback, usePaginationConfig } from '@publica/ui-common-utils'
import { EmailStatusTags, FilterColumnType, FilterTable } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationEmailSetsQuery } from '../../../../../data'
import { EmailSet, Operation } from '../types'
import { EmailSetActions } from './EmailSetActions'
import { EmailTableForEmailSet } from './EmailTableForEmailSet'

type EmailSetTableProps = {
    operation: Operation
}

type EmailSetTableFilters = {
    subject?: string[]
    state?: EmailSetStatus[]
}

const emailSetStatusFilters = ['includeArchived'] as const

type EmailSetStatus = (typeof emailSetStatusFilters)[number]

export const EmailSetTable: FC<EmailSetTableProps> = ({ operation }) => {
    const mutable = operationIsOpen(operation)
    const { t } = useEmailSetTableTranslation()

    const { data, loading, fetchMore } = useGetOperationEmailSetsQuery({
        variables: {
            operationId: operation.id,
        },
        pollInterval: usePollingRate(),
    })

    const emailSets = useMemo(
        () =>
            orderBy(
                (data?.operation?.emailSets.edges ?? []).map(edge => edge.node),
                ds => ds.createdAt,
                'desc'
            ),
        [data?.operation?.emailSets.edges]
    )

    const expandable: ExpandableConfig<EmailSet> = useMemo(
        () => ({
            expandedRowRender: (emailSet: EmailSet) => (
                <EmailTableForEmailSet emailSet={emailSet} mutable={mutable} operation={operation} />
            ),
        }),
        [mutable, operation]
    )

    const columns = useMemo<(ColumnType<EmailSet> | FilterColumnType<EmailSet>)[]>(
        () => [
            {
                title: t('subject'),
                key: 'subject',
                align: 'left',
                render: (_, { subject }) => subject,
                filterValue: true,
            },
            {
                title: t('state'),
                key: 'state',
                render: (_, { emails }) => <EmailStatusTags emails={emails} />,
                filters: emailSetStatusFilters.map(status => ({
                    text: <EmailSetStatusFilterLabel status={status} />,
                    value: status,
                })),
                width: 250,
            },
            {
                title: t('created'),
                render: (_, set) => humanCreatedAt(set),
            },
            {
                title: t('actions'),
                width: 150,
                align: 'right',
                render: (_, emailSet) => <EmailSetActions emailSet={emailSet} />,
            },
        ],
        [t]
    )

    const pagination = usePaginationConfig(data?.operation?.emailSets.pageInfo)

    const onChange = useAsyncCallback(
        async (pagination: TablePaginationConfig, filters: EmailSetTableFilters) => {
            const { pageSize, current } = pagination
            const { state, subject } = filters

            const statuses = (state ?? []).reduce(
                (statuses, state) => ({ ...statuses, [state]: true }),
                {} as Partial<Record<EmailSetStatus, boolean>>
            )

            await fetchMore({
                variables: {
                    page: Math.max((current ?? 1) - 1, 0),
                    pageSize,
                    filters: {
                        subject,
                        ...statuses,
                    },
                },
            })
        },
        [fetchMore]
    )

    return (
        <FilterTable
            dataSource={emailSets}
            rowKey="id"
            loading={loading}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
            expandable={expandable}
        />
    )
}

const useEmailSetTableTranslation = createUseTranslation({
    EN: {
        subject: 'Subject',
        quantity: 'Number of emails',
        state: 'State',
        actions: 'Actions',
    },
    FR: {
        subject: 'Objet',
        quantity: `Quantité d'emails`,
        state: 'État',
        actions: 'Actions',
    },
})

const useEmailSetStatusFilterLabelTranslation = createUseTranslation({
    EN: {
        includeArchived: 'Include archived emails',
    },
    FR: {
        includeArchived: 'Inclure les emails archivés',
    },
})

const EmailSetStatusFilterLabel = ({ status }: { status: EmailSetStatus }) => {
    const { t } = useEmailSetStatusFilterLabelTranslation()
    return <>{t(status)}</>
}
