import { DateTime } from 'luxon'

import type { KnownLocale } from '@publica/locales'
import {
    AddressMapKey,
    type Currency,
    type DateFormat,
    addressMapKeySchema,
    booleansLookup,
    countryLookup,
    maritalStatusLookup,
    nationalityLookup,
    titlesLookup,
} from '@publica/lookups'
import {
    BooleanValue,
    DateValue,
    FloatValue,
    HasKey,
    LookupValue,
    MapValue,
    TextValue,
    addressMapValueRecordSchema,
    mapValueAsRecord,
} from '@publica/values'

export type RenderContext = {
    context: 'web' | 'document'
}

export type RenderInstruction =
    | RenderTextInstruction
    | RenderFloatInstruction
    | RenderBooleanInstruction
    | RenderDateInstruction
    | RenderLookupInstruction
    | RenderMapInstruction

type WithoutKey<T extends HasKey> = Omit<T, 'key'>

export type RenderTextInstruction = WithoutKey<TextValue> & RenderContext

export type RenderFloatInstruction = WithoutKey<FloatValue> & {
    options: {
        locale: KnownLocale
        precision?: number
        numberFormat: 'NUMBER' | 'WORDS'
        currency?: Currency
    }
} & RenderContext

export type RenderBooleanInstruction = WithoutKey<BooleanValue> & {
    options: {
        locale: KnownLocale
    }
} & RenderContext

export type RenderDateInstruction = WithoutKey<DateValue> & {
    options: {
        locale: KnownLocale
        dateFormat: DateFormat
    }
} & RenderContext

export type RenderLookupInstruction = WithoutKey<LookupValue> & {
    options: {
        locale: KnownLocale
    }
} & RenderContext

export type RenderMapInstruction = WithoutKey<MapValue> & {
    options: {
        locale: KnownLocale
        subType: 'ADDRESS'
        key?: string
    }
} & RenderContext

export const renderValue = (instruction: RenderInstruction): string => {
    switch (instruction.type) {
        case 'TextValue':
            return instruction.textValue
        case 'FloatValue':
            return renderFloatValue(instruction)
        case 'BooleanValue':
            return renderBooleanValue(instruction)
        case 'DateValue':
            return renderDateValue(instruction)
        case 'LookupValue':
            return renderLookupValue(instruction)
        case 'MapValue':
            return renderMapValue(instruction)
    }
}

const renderFloatValue = ({ floatValue, options }: RenderFloatInstruction): string => {
    // FIXME(render-float): as words
    const currencyOptions: Intl.NumberFormatOptions = {}

    if (options.currency !== undefined) {
        currencyOptions.style = 'currency'
        currencyOptions.currency = options.currency
        currencyOptions.currencyDisplay = 'narrowSymbol'
    }

    return floatValue.toLocaleString(options.locale, {
        maximumFractionDigits: options.precision,
        minimumFractionDigits: options.precision,
        ...currencyOptions,
    })
}

const renderBooleanValue = ({ booleanValue, options }: RenderBooleanInstruction): string =>
    booleansLookup.labelForKeyAndLocale(booleanValue ? 'TRUE' : 'FALSE', options.locale)

const renderDateValue = ({ dateValue, options }: RenderDateInstruction): string => {
    const rezoned = dateValue.setZone('utc').setLocale(options.locale)

    if (options.dateFormat === 'CONDENSED') {
        return rezoned.toLocaleString()
    }
    return rezoned.toLocaleString(DateTime.DATE_HUGE)
}

const renderLookupValue = ({ lookupValue, options, context }: RenderLookupInstruction): string => {
    switch (lookupValue.dictionary) {
        case 'COUNTRY':
            return countryLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
        case 'NATIONALITY':
            return renderNationalityLookupValue(lookupValue.key, options, context)
        case 'MARITAL_STATUS':
            return maritalStatusLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
        case 'TITLE':
            return titlesLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
    }
}

const renderNationalityLookupValue = (
    key: string,
    options: RenderLookupInstruction['options'],
    context: RenderContext['context']
): string => {
    let value = nationalityLookup.labelForKeyAndLocale(key, options.locale)

    if (options.locale === 'FR' && context === 'document') {
        // In french documents, the nationality needs to be lowercase
        value = value.toLocaleLowerCase()
    }

    return value
}

const renderMapValue = (instruction: RenderMapInstruction): string => {
    switch (instruction.options.subType) {
        case 'ADDRESS':
            return renderAddressMapValue(instruction)
    }
}

const renderAddressMapValue = (instruction: RenderMapInstruction): string => {
    const parsedAddressMapKey = addressMapKeySchema.safeParse(instruction.options.key)

    if (parsedAddressMapKey.success) {
        return renderAddressMapKeyValue(parsedAddressMapKey.data, instruction)
    }

    return renderCompleteAddressMapValue(instruction)
}

const renderCompleteAddressMapValue = ({ mapValue, options }: RenderMapInstruction): string => {
    const record = mapValueAsRecord(mapValue)

    const parts: string[] = []
    const { address, city, postCode, country } = addressMapValueRecordSchema.parse(record)

    const components =
        options.locale === 'EN'
            ? [address, `${city ?? ''} ${postCode ?? ''}`]
            : [address, `${postCode ?? ''} ${city ?? ''}`]

    for (const part of components) {
        if (part !== undefined) {
            const trimmed = part.trim()

            if (trimmed.length > 0) {
                parts.push(trimmed)
            }
        }
    }

    if (country !== undefined) {
        const countryLabel = countryLookup.labelForKeyAndLocale(country, options.locale)
        parts.push(countryLabel)
    }

    return parts.join(', ')
}

const renderAddressMapKeyValue = (key: AddressMapKey, { mapValue, options }: RenderMapInstruction): string => {
    const record = mapValueAsRecord(mapValue)
    const values = addressMapValueRecordSchema.parse(record)

    if (key === 'country') {
        const country = values[key]

        if (country === undefined) {
            return ''
        }

        return countryLookup.labelForKeyAndLocale(country, options.locale)
    }

    return values[key] ?? ''
}
