import { CheckCircleTwoTone, ClockCircleTwoTone, WarningTwoTone } from '@ant-design/icons'
import { Alert, Button, Space, Steps } from 'antd'
import { StepProps } from 'antd/lib'
import sortBy from 'lodash/sortBy'
import { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { fullName } from '@publica/common'
import { legalEntityRepresentationTypeLookup, legalEntityTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { ActionButton, FilterColumnType, FilterTable, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { AddParticipantStateWithProgress } from '../../types'

type ImportParticipantsPlanSummaryProps = {
    state: AddParticipantStateWithProgress[]
    loading: boolean
    onConfirm: () => void
    onCancel: () => void
}

export const ImportParticipantsPlanSummary: FC<ImportParticipantsPlanSummaryProps> = ({
    state,
    loading,
    onCancel,
    onConfirm,
}) => {
    const controlsStyles = utils.useControlsStyles()
    const { t } = useImportParticipantsPlanSummaryTranslation()

    const columns = useImportParticipantsPlanSummaryColumns()
    const rows = useMemo(() => sortBy(state, p => p.row), [state])
    const participantsToImport = useMemo(() => rows.filter(row => row.progress === 'PENDING'), [rows])

    const status = useMemo<'ERROR' | 'IN_PROGRESS' | 'PENDING' | 'COMPLETED'>(() => {
        let hasPending = false

        for (const participant of state) {
            if (participant.status === 'ERROR') {
                return 'ERROR'
            }

            if (participant.progress === 'IN_PROGRESS') {
                return 'IN_PROGRESS'
            }

            if (participant.progress === 'PENDING') {
                hasPending = true
            }
        }

        return hasPending ? 'PENDING' : 'COMPLETED'
    }, [state])

    return (
        <>
            {status === 'ERROR' ? <ErrorsInUploadedFile /> : <ImportCount participants={participantsToImport} />}

            <FilterTable<AddParticipantStateWithProgress>
                dataSource={rows}
                rowKey="row"
                columns={columns}
                loading={loading}
            />
            <div className={controlsStyles.footerControls}>
                <Space>
                    {status !== 'COMPLETED' ? (
                        <>
                            <Button onClick={onCancel} disabled={status === 'IN_PROGRESS'}>
                                {t('cancel')}
                            </Button>
                            <ActionButton
                                size="middle"
                                inProgress={status === 'IN_PROGRESS'}
                                disabled={status === 'ERROR'}
                                onClick={onConfirm}
                            >
                                {t('confirm')}
                            </ActionButton>
                        </>
                    ) : (
                        <ActionButton size="middle" onClick={onCancel}>
                            {t('back')}
                        </ActionButton>
                    )}
                </Space>
            </div>
        </>
    )
}

const useImportParticipantsPlanSummaryTranslation = createUseTranslation({
    EN: {
        confirm: 'Confirm',
    },
    FR: {
        confirm: 'Confirmer',
    },
})

const useImportParticipantsPlanSummaryColumns: () => FilterColumnType<AddParticipantStateWithProgress>[] = () => {
    const { t } = useImportParticipantsPlanSummaryColumnsTranslation()
    const locale = useCurrentLocale()

    return useMemo<FilterColumnType<AddParticipantStateWithProgress>[]>(
        () => [
            {
                title: t('row'),
                width: 50,
                render: (_, { row }) => row,
            },
            {
                align: 'center',
                width: 50,
                render: (_, { progress }) => {
                    switch (progress) {
                        case 'PENDING':
                            return <ClockCircleTwoTone twoToneColor={colors.grey6} />
                        case 'IN_PROGRESS':
                            return icons.Loading
                        case 'COMPLETED':
                            return <CheckCircleTwoTone twoToneColor={colors.success} />
                        case 'WONT_START':
                            return <WarningTwoTone twoToneColor={colors.warning} />
                    }
                },
            },
            {
                title: t('participant'),
                render: (_, participant): string => {
                    if (participant.status !== 'ERROR') {
                        return participant.uploadedParticipant.legalEntity.name
                    }

                    switch (participant.errorType) {
                        case 'PARSE_ERROR':
                            return t('parseError')
                        case 'ACCOUNT_IS_ADMIN':
                            return t('accountIsAdmin')
                        case 'ACCOUNT_LEGAL_ENTITY_MISMATCH':
                            return t('accountDoesNotMatchLegalEntity')
                        case 'DUPLICATE_PARTICIPANT':
                            return t('duplicateParticipant')
                    }
                },
            },
            {
                title: t('participantType'),
                render: (_, participant) => {
                    if (participant.status === 'ERROR') {
                        return ''
                    }

                    return legalEntityTypeLookup.labelForKeyAndLocale(
                        participant.uploadedParticipant.legalEntity.type,
                        locale
                    )
                },
            },
            {
                title: t('account'),
                render: (_, participant) => {
                    if (participant.status === 'ERROR') {
                        return ''
                    }

                    return fullName(participant.uploadedParticipant.account)
                },
            },
            {
                title: t('representationType'),
                render: (_, participant) => {
                    if (participant.status === 'ERROR') {
                        return ''
                    }

                    return legalEntityRepresentationTypeLookup.labelForKeyAndLocale(
                        participant.uploadedParticipant.legalEntity.representationType,
                        locale
                    )
                },
            },
            {
                title: t('actionsToTake'),
                render: (_, p) => {
                    if (p.status === 'ERROR') {
                        return ''
                    }

                    const { account, legalEntity, participant } = p.steps

                    const accountStep = ((): StepProps => {
                        let title: string
                        let status: 'wait' | 'process' | 'finish'

                        switch (account.state) {
                            case 'ACCOUNT_DOES_NOT_EXIST':
                                title = t('createAccount')
                                status = 'wait'
                                break
                            case 'CREATING_ACCOUNT':
                                title = t('creatingAccount')
                                status = 'process'
                                break
                            case 'READY':
                                title = t('accountExists')
                                status = 'finish'
                        }

                        return {
                            title,
                            status,
                        }
                    })()

                    const legalEntityStep = ((): StepProps => {
                        let title: string
                        let status: 'wait' | 'process' | 'finish'

                        switch (legalEntity.state) {
                            case 'LEGAL_ENTITY_DOES_NOT_EXIST':
                                title = t('createLegalEntity')
                                status = 'wait'
                                break
                            case 'LEGAL_ENTITY_EXISTS_AND_NEEDS_LINKING':
                                title = t('legalEntityNeedsLinking')
                                status = 'wait'
                                break
                            case 'CREATING_LEGAL_ENTITY':
                                title = t('creatingLegalEntity')
                                status = 'process'
                                break
                            case 'LINKING_LEGAL_ENTITY':
                                title = t('linkingLegalEntity')
                                status = 'process'
                                break
                            case 'READY':
                                title = t('legalEntityExists')
                                status = 'finish'
                        }

                        return {
                            title,
                            status,
                        }
                    })()

                    const participantStep = ((): StepProps => {
                        let title: string
                        let status: 'wait' | 'process' | 'finish'

                        switch (participant.state) {
                            case 'PARTICIPANT_DOES_NOT_EXIST':
                                title = t('createParticipant')
                                status = 'wait'
                                break
                            case 'CREATING_PARTICIPANT':
                                title = t('creatingParticipant')
                                status = 'process'
                                break
                            case 'READY':
                                title = t('participantExists')
                                status = 'finish'
                        }

                        return {
                            title,
                            status,
                        }
                    })()

                    const items: StepProps[] = [accountStep, legalEntityStep, participantStep].map(step => ({
                        ...step,
                        icon: step.status === 'process' ? icons.Loading : undefined,
                    }))

                    return <Steps items={items} size="small" />
                },
                align: 'left',
            },
        ],
        [locale, t]
    )
}

const useImportParticipantsPlanSummaryColumnsTranslation = createUseTranslation({
    EN: {
        row: 'Row',
        account: 'Account',
        participant: 'Participant',
        participantType: 'Type',
        actionsToTake: `Planned actions`,
        createAccount: `An account will be created`,
        createLegalEntity: `A legal entity will be created`,
        createParticipant: `A participant will be created`,
        noop: `No actions are required`,
        error: `An error occurred`,
        parseError: `There is an unknown problem with the line`,
        accountIsAdmin: 'The specified account is an administrator',
        accountDoesNotMatchLegalEntity: 'This legal entity is already represented by another account',
        duplicateParticipant: 'The participant appears multiple times in the import file',
        linkLegalEntity: 'The legal entity will be linked to the account',
        accountExists: `The account exists`,
        creatingAccount: `Creating account`,
        creatingLegalEntity: `Creating the legal entity`,
        legalEntityExists: `The legal entity exists`,
        legalEntityNeedsLinking: `The legal entity must be linked to the account`,
        linkingLegalEntity: `Linking the legal entity`,
        creatingParticipant: `Creating the participant`,
        participantExists: `The participant exists`,
        representationType: `Representation`,
    },
    FR: {
        row: 'Ligne',
        account: 'Compte',
        participant: 'Participant',
        participantType: 'Type',
        actionsToTake: `Actions à prendre`,
        createAccount: `Un compte sera créé`,
        accountExists: `Le compte existe`,
        creatingAccount: `Création du compte`,
        createLegalEntity: `Une entité légale sera créée`,
        creatingLegalEntity: `Création de l'entité légale`,
        legalEntityExists: `L'entité légale existe`,
        legalEntityNeedsLinking: `L'entité légale doit être reliée au compte`,
        linkingLegalEntity: `Création du lien avec le compte`,
        createParticipant: `Un participant sera créé`,
        creatingParticipant: `Création du participant`,
        participantExists: `Le participant existe`,
        noop: `Aucune action n'est requise`,
        error: `Une erreur s'est produite`,
        parseError: `La ligne n'est pas correctement renseignée`,
        accountIsAdmin: 'Le compte correspond à un compte administrateur',
        accountDoesNotMatchLegalEntity: `Cette entité légale est déjà représentée par un autre compte`,
        duplicateParticipant: `Le participant apparaît plusieurs fois dans l'import`,
        linkLegalEntity: `L'entité légale sera liée au compte`,
        representationType: `Représentation`,
    },
})

const useErrorsInUploadedFileTranslation = createUseTranslation({
    FR: {
        error: `Le fichier contient une ou plusieurs lignes erronées`,
        errorTitle: `Le fichier n'est pas exploitable`,
    },
    EN: {
        error: 'The file contains one or more rows with errors',
        errorTitle: 'The file is invalid',
    },
})

const ErrorsInUploadedFile: FC = () => {
    const styles = useImportMessageStyles()
    const { t } = useErrorsInUploadedFileTranslation()

    const message = t('errorTitle')
    const description = t('error')

    return <Alert className={styles.message} type="error" message={message} description={description} />
}

type ImportCountProps = {
    participants: unknown[]
}

const useImportCountTranslation = createUseTranslation({
    FR: {
        message_one: `Un participant sera importé`,
        message_other: `{{count}} participants seront importés`,
        allParticipantsAlreadyImported: `Tous les participants existent déjà`,
    },
    EN: {
        message_one: `A single participant will be imported`,
        message_other: `{{count}} participants will be imported`,
        allParticipantsAlreadyImported: 'All participants already exist',
    },
})

const ImportCount: FC<ImportCountProps> = ({ participants }) => {
    const styles = useImportMessageStyles()
    const { t } = useImportCountTranslation()

    if (participants.length === 0) {
        return <Alert className={styles.message} type="success" description={t('allParticipantsAlreadyImported')} />
    }

    return (
        <Alert className={styles.message} type="success" description={t('message', { count: participants.length })} />
    )
}

const useImportMessageStyles = createUseStyles({
    message: {
        '& .ant-alert-message': {
            fontSize: '1em',
            fontWeight: 'bold',
            marginBottom: 10,
        },
        marginBottom: 15,
    },
    list: {
        '& li:not(:last-child)': {
            marginBottom: 5,
        },
        marginBottom: 0,
    },
})
