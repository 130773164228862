import { Card, Form, FormInstance, FormProps } from 'antd'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { useEffect, useMemo } from 'react'

import { LocalizedString } from '@publica/api-graphql'
import { useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FormValueMap } from '@publica/values'

import { VerticalSpacer } from '../VerticalSpacer'
import { FieldFormItem } from './FieldFormItem'
import { Field } from './types'

type FormSection = {
    id: string
    name: LocalizedString
    position?: number | null
}

type FieldWithFormSection = Field & {
    formSection?: FormSection | null
}

type EditParticipantValuesProps = {
    fields: FieldWithFormSection[]
    values: FormValueMap
    form?: FormInstance
    onFieldsChange?: FormProps['onFieldsChange']
    selfRepresented?: boolean
}

export const EditParticipantValues: FC<EditParticipantValuesProps> = ({
    fields,
    values: initialValues,
    form,
    onFieldsChange,
    selfRepresented,
}) => {
    const [formInstance] = Form.useForm(form)

    const resolveLocalizedString = useLocalizedStringResolver()

    const { formSections, fieldsByFormSection, fieldsWithoutFormSection } = useMemo(() => {
        const formSections: Record<string, FormSection> = {}
        const fieldsByFormSection: Record<string, Field[]> = {}
        const fieldsWithoutFormSection: Field[] = []

        for (const field of fields) {
            const formSection = field.formSection

            const fieldWithSelfRepresentation = {
                ...field,
                lockedDueToSelfRepresentation: selfRepresented && selfRepresentedFields.includes(field.key),
            }

            if (!isNil(formSection)) {
                formSections[formSection.id] = formSection
                let fieldsForFormSection = fieldsByFormSection[formSection.id]
                if (fieldsForFormSection === undefined) {
                    fieldsByFormSection[formSection.id] = []
                    fieldsForFormSection = fieldsByFormSection[formSection.id]
                }
                fieldsForFormSection?.push(fieldWithSelfRepresentation)
            } else {
                fieldsWithoutFormSection.push(fieldWithSelfRepresentation)
            }
        }

        return {
            formSections: sortBy(Object.values(formSections), formSection => formSection.position),
            fieldsByFormSection,
            fieldsWithoutFormSection,
        }
    }, [fields, selfRepresented])

    useEffect(() => {
        formInstance.validateFields().catch(() => {})
    })

    return (
        <Form
            form={formInstance}
            layout="vertical"
            requiredMark={false}
            initialValues={initialValues}
            onFieldsChange={onFieldsChange}
        >
            <VerticalSpacer size={15}>
                {formSections.map(({ id, name }) => {
                    return (
                        <Card key={id} title={resolveLocalizedString(name)}>
                            {sortBy(fieldsByFormSection[id], field => field.position).map(field => (
                                <FieldFormItem key={field.key} field={field} />
                            ))}
                        </Card>
                    )
                })}
                {sortBy(fieldsWithoutFormSection, field => field.position).map(field => (
                    <FieldFormItem key={field.key} field={field} />
                ))}
            </VerticalSpacer>
        </Form>
    )
}

const selfRepresentedFields = ['title', 'firstName', 'lastName']
