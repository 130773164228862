import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { FC } from '@publica/ui-common-utils'

import { DatePicker } from '../../../'
import { DateFieldParameters, Field } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

type DateValueFormItemProps = {
    field: Field<DateFieldParameters>
}

const dateFormat = (date: DateTime) => date.toLocaleString()

const truncateTime = (date: DateTime | null) => {
    if (!DateTime.isDateTime(date)) {
        return date
    }

    return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).setZone('utc', { keepLocalTime: true })
}

export const DateValueFormItem: FC<DateValueFormItemProps> = ({ field }) => {
    const dateValueRules = useCommonRules('object')

    const disable = useCallback(
        (date: DateTime): boolean => {
            const diff = date.diffNow('second')

            switch (field.parameters.range) {
                case 'ANY':
                    return false
                case 'ONLY_FUTURE':
                    return diff.seconds < 0
                case 'ONLY_PAST':
                    return diff.seconds > 0
            }
        },
        [field.parameters]
    )

    return (
        <FormItem field={field} rules={dateValueRules} normalize={truncateTime}>
            <DatePicker disabledDate={disable} format={dateFormat} disabled={field.lockedDueToSelfRepresentation} />
        </FormItem>
    )
}
