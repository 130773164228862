import isEqual from 'lodash/isEqual'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import { DateTime } from 'luxon'

import type { FieldParameters, LookupFieldParameters } from '@publica/api-graphql'
import { countryLookup, maritalStatusLookup, nationalityLookup, titlesLookup } from '@publica/lookups'
import { type RequireTypeName, assert, buildMap, lang } from '@publica/utils'
import { clone } from '@publica/utils'

import { valuesArraySchema } from './schema'
import type {
    AsGraphQLValue,
    AsValue,
    GraphQLDateValue,
    GraphQLFloatValue,
    GraphQLLookupValue,
    GraphQLMapValue,
    GraphQLTextValue,
    GraphQLValue,
    GraphQLValueInput,
    GraphQLValueInputs,
    LookupValue,
    MapValue,
    RawValues,
    Value,
} from './types'

type Field<P = RequireTypeName<FieldParameters>> = {
    key: string
    position?: null | number
    parameters: P
}

export const parseValues = (rawValues: RawValues): Value[] => valuesArraySchema.parse(rawValues)

export const serializeValues = (values: Value[]): Readonly<NonNullable<RawValues>> =>
    values.reduce((values, value) => {
        const newValues = [...values]
        const clonedValue = clone.cloneDeep(value)
        let rawValue: NonNullable<RawValues> | undefined

        switch (clonedValue.type) {
            case 'TextValue':
                clonedValue.textValue = clonedValue.textValue.trim()

                // Drop empty text values
                if (clonedValue.textValue.length === 0) {
                    break
                }

                rawValue = clonedValue
                break

            case 'LookupValue':
                // Drop invalid lookup values
                if (isValidLookupValue(clonedValue)) {
                    rawValue = clonedValue
                }
                break

            case 'DateValue':
                // Convert the date to an ISO string
                rawValue = {
                    ...clonedValue,
                    dateValue: clonedValue.dateValue.toISO(),
                }
                break

            default:
                rawValue = clonedValue
        }

        if (rawValue !== undefined) {
            newValues.push(rawValue)
        }

        return newValues
    }, [] as NonNullable<RawValues>[])

export type HasKey = {
    key: string
}

export const throwOnDuplicateKeys = (records: HasKey[]): void => {
    const seenKeys = new Set<string>()

    for (const rec of records) {
        if (seenKeys.has(rec.key)) {
            throw new Error(`Duplicated key: ${rec.key}`)
        }
        seenKeys.add(rec.key)
    }
}

export const flattenByKey = <R extends HasKey>(records: R[]): R[] => {
    const mapByKey = buildMap('key', records)
    return Object.values(mapByKey)
}

const shortTypesToValueTypes = {
    text: 'TextValue',
    float: 'FloatValue',
    boolean: 'BooleanValue',
    date: 'DateValue',
} as const

type ShortTypeMap = typeof shortTypesToValueTypes
type ShortType = keyof typeof shortTypesToValueTypes

type NullableValueRecordKeyType = { type: ShortType; allowMissing: boolean }

type BuildRecordSpec = Record<string, ShortType | NullableValueRecordKeyType>

type ValueTypeToJSType = {
    TextValue: string
    FloatValue: number
    BooleanValue: boolean
    DateValue: DateTime
}

export type ValueRecord<S extends BuildRecordSpec> = {
    [K in keyof S]: S[K] extends NullableValueRecordKeyType
        ? NullableValueRecordKeyTypeToJSType<S[K]> | undefined
        : S[K] extends ShortType
          ? ShortTypeAsJSType<S[K]>
          : never
}

type ShortTypeAsValueType<S extends ShortType> = ShortTypeMap[S]
type ShortTypeAsJSType<S extends ShortType> = ValueTypeToJSType[ShortTypeAsValueType<S>]
type NullableValueRecordKeyTypeToJSType<N extends NullableValueRecordKeyType> = ShortTypeAsJSType<N['type']>

export const buildRecordFromValues = <S extends BuildRecordSpec>(values: Value[], spec: S): ValueRecord<S> => {
    const record: Record<string, ValueTypeToJSType[keyof ValueTypeToJSType]> = {}
    const requiredKeys = new Set<string>()
    const keysOfInterest = new Set<string>()
    const keyTypes: Record<string, ShortType> = {}

    for (const [key, keySpec] of Object.entries(spec)) {
        let allowMissing = false
        let keyType: ShortType

        if (isString(keySpec)) {
            keyType = keySpec
        } else {
            keyType = keySpec.type
            allowMissing = keySpec.allowMissing
        }

        keysOfInterest.add(key)

        if (!allowMissing) {
            requiredKeys.add(key)
        }

        keyTypes[key] = keyType
    }

    for (const val of values) {
        if (!keysOfInterest.has(val.key)) {
            continue
        }

        const key = val.key
        const expectedType = shortTypesToValueTypes[keyTypes[key]!]

        if (val.type !== expectedType) {
            throw new Error(`Expected key ${key} to be of type ${expectedType} but is ${val.type}`)
        }

        switch (val.type) {
            case 'TextValue':
                record[key] = val.textValue
                break
            case 'FloatValue':
                record[key] = val.floatValue
                break
            case 'BooleanValue':
                record[key] = val.booleanValue
                break
            case 'DateValue':
                record[key] = val.dateValue
                break
            default:
                lang.NotExhaustedError(val)
        }

        requiredKeys.delete(key)
    }

    if (requiredKeys.size > 0) {
        throw new Error(`Unable to find keys for record ${Array.from(requiredKeys).join(', ')}`)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return record as any
}

export const valueToGraphQLValue = <V extends Value>(val: V, id: string): AsGraphQLValue<V> => {
    switch (val.type) {
        case 'BooleanValue':
            return { ...val, __typename: val.type, id }
        case 'DateValue':
            return { ...val, __typename: val.type, id }
        case 'FloatValue':
            return { ...val, __typename: val.type, id }
        case 'TextValue':
            return { ...val, __typename: val.type, id }
        case 'LookupValue':
            return { ...val, __typename: val.type, id }
        case 'MapValue':
            return { ...val, __typename: val.type, id }
    }
}

export const graphQLValueToValue = <G extends GraphQLValue>(val: G): AsValue<G> => {
    switch (val.__typename) {
        case 'BooleanValue':
            return { ...val, type: val.__typename }
        case 'DateValue':
            return { ...val, type: val.__typename }
        case 'FloatValue':
            return { ...val, type: val.__typename }
        case 'TextValue':
            return { ...val, type: val.__typename }
        case 'LookupValue':
            return { ...val, type: val.__typename }
        case 'MapValue':
            return { ...val, type: val.__typename }
    }
}

export const graphQLInputValuesToValues = (val: GraphQLValueInputs): Value[] => {
    return [
        ...(val.textValues ?? []).map(v => ({ type: 'TextValue', ...v }) as const),
        ...(val.floatValues ?? []).map(v => ({ type: 'FloatValue', ...v }) as const),
        ...(val.booleanValues ?? []).map(v => ({ type: 'BooleanValue', ...v }) as const),
        ...(val.dateValues ?? []).map(v => ({ type: 'DateValue', ...v }) as const),
        ...(val.lookupValues ?? []).map(v => ({ type: 'LookupValue', ...v }) as const),
        ...(val.mapValues ?? []).map(v => ({ type: 'MapValue', ...v }) as const),
    ]
}

export const buildValueMap = (values: Value[]): Record<string, Value> =>
    values.reduce((values, val) => ({ ...values, [val.key]: val }), {} as Record<string, Value>)

const isValidLookupValue = (lookupValue: LookupValue): boolean => {
    const { dictionary, key } = lookupValue.lookupValue

    switch (dictionary) {
        case 'COUNTRY':
            return countryLookup.keyIsValid(key)
        case 'MARITAL_STATUS':
            return maritalStatusLookup.keyIsValid(key)
        case 'NATIONALITY':
            return nationalityLookup.keyIsValid(key)
        case 'TITLE':
            return titlesLookup.keyIsValid(key)
    }
}

export const mapValueAsRecord = (mapValue: MapValue['mapValue']): Record<string, string> =>
    mapValue.reduce((record, { key, value }) => ({ ...record, [key]: value }), {} as Record<string, string>)

export const recordAsMapValue = (record: Record<string, string | undefined>): MapValue['mapValue'] =>
    reduce(
        record,
        (mapValueEntries, value, key) => {
            if (value === undefined) {
                return mapValueEntries
            }

            return [...mapValueEntries, { key, value }]
        },
        [] as MapValue['mapValue']
    )

export const graphQLValuesToFormValues = (values: GraphQLValue[], fields: Field[]) => {
    return valuesToFormValues(
        values.map(val => ({ type: val.__typename, ...val }) as Value),
        fields
    )
}

export type FormValueMap = Record<string, FormValueType>
export type FormValueType = string | boolean | number | undefined | DateTime | Record<string, string | undefined>

export const formValuesToGraphQLValueInputs = (
    formValues: Record<string, FormValueType>,
    fields: Field[]
): GraphQLValueInputs => {
    const fieldsByKey = buildMap('key', fields)

    return reduce(
        formValues,
        (graphqlValueInputs, value, key) => {
            const field: Field | undefined = fieldsByKey[key]
            assert.defined(field)
            const parameterType = field.parameters.__typename
            assert.defined(parameterType)

            switch (parameterType) {
                case 'TextFieldParameters':
                    if (!isString(value)) {
                        break
                    }

                    graphqlValueInputs.textValues.push({
                        key,
                        textValue: value,
                    })
                    break
                case 'DateFieldParameters':
                    if (!DateTime.isDateTime(value)) {
                        break
                    }

                    graphqlValueInputs.dateValues.push({
                        key,
                        dateValue: value,
                    })
                    break
                case 'LookupFieldParameters':
                    if (!isString(value)) {
                        break
                    }

                    graphqlValueInputs.lookupValues.push({
                        key,
                        lookupValue: {
                            key: value,
                            dictionary: (field.parameters as LookupFieldParameters).dictionary,
                        },
                    })
                    break
                case 'MapFieldParameters':
                    if (!isObject(value) || DateTime.isDateTime(value)) {
                        break
                    }

                    graphqlValueInputs.mapValues.push({
                        key,
                        mapValue: recordAsMapValue(value),
                    })
                    break
                case 'FloatFieldParameters':
                    if (!isNumber(value)) {
                        break
                    }

                    graphqlValueInputs.floatValues.push({
                        key,
                        floatValue: value,
                    })

                    break
                default:
                    throw lang.NotExhaustedError(parameterType)
            }

            return graphqlValueInputs
        },
        {
            textValues: [] as GraphQLValueInput<GraphQLTextValue>[],
            dateValues: [] as GraphQLValueInput<GraphQLDateValue>[],
            lookupValues: [] as GraphQLValueInput<GraphQLLookupValue>[],
            mapValues: [] as GraphQLValueInput<GraphQLMapValue>[],
            floatValues: [] as GraphQLValueInput<GraphQLFloatValue>[],
        }
    )
}

export const formValuesToValues = (formValues: Record<string, FormValueType>, fields: Field[]): Value[] => {
    const fieldsByKey = buildMap('key', fields)

    return reduce(
        formValues,
        (values, value, key) => {
            const field: Field | undefined = fieldsByKey[key]
            assert.defined(field)

            const parameterType = field.parameters.__typename
            assert.defined(parameterType)

            switch (parameterType) {
                case 'TextFieldParameters':
                    if (!isString(value)) {
                        break
                    }

                    values.push({
                        type: 'TextValue',
                        key,
                        textValue: value,
                    })
                    break
                case 'DateFieldParameters':
                    if (!DateTime.isDateTime(value)) {
                        break
                    }

                    values.push({
                        key,
                        dateValue: value,
                        type: 'DateValue',
                    })
                    break
                case 'LookupFieldParameters':
                    if (!isString(value)) {
                        break
                    }

                    values.push({
                        key,
                        lookupValue: {
                            key: value,
                            dictionary: (field.parameters as LookupFieldParameters).dictionary,
                        },
                        type: 'LookupValue',
                    })
                    break
                case 'MapFieldParameters':
                    if (!isObject(value) || DateTime.isDateTime(value)) {
                        break
                    }

                    values.push({
                        key,
                        mapValue: recordAsMapValue(value),
                        type: 'MapValue',
                    })
                    break
                case 'FloatFieldParameters':
                    if (!isNumber(value)) {
                        break
                    }

                    values.push({
                        floatValue: value,
                        key,
                        type: 'FloatValue',
                    })
                    break
                default:
                    throw lang.NotExhaustedError(parameterType)
            }

            return values
        },
        [] as Value[]
    )
}

export const valuesToFormValues = (values: Value[], fields: Field[]) => {
    const fieldsByKey = buildMap('key', fields)

    return values.reduce((values, value) => {
        const field = fieldsByKey[value.key]

        if (field === undefined) {
            return values
        }

        switch (value.type) {
            case 'TextValue':
                values[value.key] = value.textValue
                break
            case 'LookupValue':
                values[value.key] = value.lookupValue.key
                break
            case 'BooleanValue':
                values[value.key] = value.booleanValue
                break
            case 'FloatValue':
                values[value.key] = value.floatValue
                break
            case 'DateValue':
                values[value.key] = value.dateValue
                break
            case 'MapValue':
                values[value.key] = mapValueAsRecord(value.mapValue)
                break
            default:
                throw lang.NotExhaustedError(value)
        }

        return values
    }, {} as FormValueMap)
}

export const valuesAreEqual = (a?: Value, b?: Value): boolean => {
    if (a === undefined || b === undefined) {
        return false
    }

    return isEqual(a, b)
}
