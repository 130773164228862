import { DateTime } from 'luxon'

import { getCurrentLocale } from '@publica/ui-common-i18n'

export const humanDate = (date: DateTime, dateOnly = false): string => {
    if (dateOnly) {
        return date.toLocaleString(DateTime.DATE_SHORT, { locale: getCurrentLocale() })
    }
    return date.toLocal().toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, { locale: getCurrentLocale() })
}

type WithCreatedAt = {
    createdAt: DateTime
}

export const humanCreatedAt = (withCreatedAt: WithCreatedAt) => humanDate(withCreatedAt.createdAt)
