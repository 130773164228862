import { Card, Tabs } from 'antd'
import { useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Layout, useTabs } from '@publica/ui-web-components'

import { Account, Accounts, Operations } from './pages'

const account = <Account />

// TODO(admin-ui): manage legal entities
// TODO(admin-ui): manage accounts

export const App: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useAppTranslations()

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/operations')
        }
    }, [location, navigate])

    const tabs = useTabs(
        useMemo(
            () => [
                {
                    key: 'operations',
                    label: t('operations'),
                    content: <Operations />,
                },
                {
                    key: 'accounts',
                    label: t('accounts'),
                    content: <Accounts />,
                },
            ],
            [t]
        )
    )

    const root = useMemo(
        () => (
            <Card>
                <Tabs
                    defaultActiveKey="operations"
                    activeKey={tabs.activeTab}
                    onTabClick={tabs.onChange}
                    items={tabs.items}
                />
            </Card>
        ),
        [tabs.activeTab, tabs.items, tabs.onChange]
    )

    return (
        <Layout>
            <Routes>
                <Route path="/account" element={account} />
                <Route path="*" element={root} />
            </Routes>
        </Layout>
    )
}

const useAppTranslations = createUseTranslation({
    EN: {
        operations: 'Operations',
        accounts: 'Accounts',
    },
    FR: {
        operations: 'Opérations',
        accounts: 'Comptes',
    },
})

export default App
