import { useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { EmailActionsProps, EmailControlsProps, EmailTable } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationEmailSetEmailsQuery } from '../../../../../../data'
import { EmailActions, EmailControls } from '../../../../../components'
import { EmailSet, Operation } from '../../types'

type EmailTableForEmailSetProps = {
    operation: Operation
    mutable: boolean
    emailSet: EmailSet
}

export const EmailTableForEmailSet: FC<EmailTableForEmailSetProps> = ({ mutable, emailSet, operation }) => {
    const { data, loading } = useGetOperationEmailSetEmailsQuery({
        variables: {
            emailSetId: emailSet.id,
            operationId: operation.id,
        },
        pollInterval: usePollingRate(),
    })

    const emailSetWithEmails = useMemo(
        () => ({ ...emailSet, emails: data?.operation?.emailSet.emails ?? [] }),
        [data?.operation?.emailSet.emails, emailSet]
    )

    const config = useMemo(() => ({ mutable }), [mutable])

    return (
        <EmailTable
            emails={emailSetWithEmails.emails}
            config={config}
            loading={loading}
            emailActions={renderEmailActions}
            emailControls={renderEmailControls}
        />
    )
}

const renderEmailActions = (props: EmailActionsProps) => <EmailActions {...props} />
const renderEmailControls = (props: EmailControlsProps) => <EmailControls {...props} />
