import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ModalForm, icons } from '@publica/ui-web-components'

import { Account } from '../../types'
import { useEditAccountForm } from './EditAccountForm'

type EditAccountActionProps = {
    account: Account
}

export const EditAccountAction: FC<EditAccountActionProps> = ({ account }) => {
    const form = useEditAccountForm(account)

    const { t } = useEditAccountActionTranslation()

    return <ModalForm form={form} icon={icons.Add} title={t('edit')} okText={t('edit')} buttonSize="small" />
}

const useEditAccountActionTranslation = createUseTranslation({
    FR: {
        edit: 'Modifier',
    },
    EN: {
        edit: 'Edit',
    },
})
