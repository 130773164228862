import { LoadingOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import saveAs from 'file-saver'
import { useState } from 'react'

import { DocumentFile as BaseDocumentFile } from '@publica/api-graphql'
import { documentFileName } from '@publica/common'
import { CreateDocumentFileArchiveRequest } from '@publica/schemas'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { useApiClient } from '@publica/ui-common-network'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { sleep } from '@publica/utils'

type DocumentFile = Pick<BaseDocumentFile, 'id' | 'name' | 'type' | 'format'>

type UseDownloadArchiveOptions = {
    operationId: string
    documentFileIds: string[]
    groupBy?: CreateDocumentFileArchiveRequest['groupBy']
    name?: string
}

const useDownloadArchiveTranslations = createUseTranslation({
    FR: {
        archiveCreating: `Téléchargement en cours de préparation`,
        archiveCreatingDescription: `Une archive est en cours de préparation. Le téléchargement commencera bientôt.`,
    },
    EN: {
        archiveCreating: 'Preparing download',
        archiveCreatingDescription: 'An archive is being created. Your download will start shortly.',
    },
})

export const useDownloadArchive = (options: UseDownloadArchiveOptions) => {
    const [downloading, setDownloading] = useState(false)
    const client = useApiClient()
    const { t } = useDownloadArchiveTranslations()
    const [notificationApi, notificationContext] = notification.useNotification()

    const { groupBy, name, operationId, documentFileIds } = options

    const downloadArchive = useAsyncCallback(async () => {
        setDownloading(true)

        const request: CreateDocumentFileArchiveRequest = {
            documentFileIds,
            operationId,
            groupBy,
        }

        const key = 'documentFileArchive'

        await new Promise<void>((resolve, reject) => {
            void client.documents
                .createArchive(request)
                .then(async data => {
                    while (data.status !== 'READY') {
                        notificationApi.open({
                            key,
                            message: t('archiveCreating'),
                            description: t('archiveCreatingDescription'),
                            duration: 0,
                            icon: <LoadingOutlined />,
                        })
                        await sleep(1000)
                        data = await client.documents.createArchive(request)
                    }
                    notificationApi.destroy(key)
                    const archive = await client.documents.downloadArchive(data.id)
                    const nameWithExt =
                        name === undefined ? 'Archive.zip' : name.endsWith('.zip') ? name : `${name}.zip`
                    saveAs(archive.data, nameWithExt)
                })
                .then(() => resolve())
                .catch(e => reject(e))
        }).finally(() => {
            setDownloading(false)
        })
    }, [documentFileIds, operationId, groupBy, client.documents, notificationApi, name, t])

    return [downloading, downloadArchive, notificationContext] as const
}

export const useDownloadDocument = () => {
    const [downloading, setDownloading] = useState(false)
    const client = useApiClient()
    const locale = useCurrentLocale()

    const downloadDocument = useAsyncCallback(
        async (document: DocumentFile) => {
            setDownloading(true)

            const download = await client.documents.download(document.id)
            const name = documentFileName(document, locale)

            saveAs(download.data, name)

            setDownloading(false)
        },
        [client.documents, locale]
    )

    return [downloading, downloadDocument] as const
}
