import { Card, Col, Form, Row, Space } from 'antd'
import { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { isGraphQLUserErrorOfType } from '@publica/common'
import { useAuthState } from '@publica/ui-common-auth'
import { createUseTranslation, i18n } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'

import { AccountForm, AccountFormValues } from '../AccountForm'
import { ActionButton } from '../ActionButton'

export type ManageAccountFormValues = Omit<AccountFormValues, 'phoneNumber'> & { phoneNumber?: string }

type ManageAccountProps = {
    checkEmailIsUnique: (email: string) => Promise<boolean>
    onCancel?: () => void
    onUpdate: (id: string, values: ManageAccountFormValues) => Promise<void>
    onComplete?: () => void
    allowCancel?: boolean
}

const useManageAccountTranslation = createUseTranslation({
    FR: {
        manage: 'Gérer votre compte',
        accountEmailAlreadyUsed: 'Cet email est déjà utilisé',
    },
    EN: {
        accountEmailAlreadyUsed: 'This email address is already used',
        manage: 'Manage your account',
    },
})

export const ManageAccount: FC<ManageAccountProps> = ({
    onCancel,
    onUpdate: onParentUpdate,
    onComplete,
    checkEmailIsUnique,
    allowCancel = true,
}) => {
    const { t } = useManageAccountTranslation()
    const styles = useManageAccountFormStyles()

    const [isUpdating, setIsUpdating] = useState(false)

    const { state } = useAuthState()

    const account = useMemo(() => state.getAccountOrThrow(), [state])

    const [form] = Form.useForm<AccountFormValues>()

    const onUpdate = useAsyncCallback(async () => {
        setIsUpdating(true)
        try {
            await form.validateFields()

            const { phoneNumber, ...accountValues } = form.getFieldsValue()

            await onParentUpdate(account.id, {
                ...accountValues,
                phoneNumber: phoneNumber?.validNumber,
            })
                .then(async () => state.refreshToken())
                .then(async ({ account }) => i18n.changeLanguage(account.locale))
                .then(() => onComplete?.())
                .catch(error => {
                    if (isGraphQLUserErrorOfType(error, 'ALREADY_IN_USE')) {
                        form.setFields([
                            {
                                name: 'email',
                                errors: [t('accountEmailAlreadyUsed')],
                            },
                        ])
                    } else {
                        throw error
                    }
                })

            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            setIsUpdating(false)
        }
    }, [account.id, form, onComplete, onParentUpdate, state, t])

    const disabled = useMemo(
        () =>
            account.type !== 'ADMIN'
                ? undefined
                : {
                      firstName: true,
                      lastName: true,
                      email: true,
                  },
        [account.type]
    )

    const requirePhoneNumber = account.type !== 'ADMIN'
    const requireTitle = account.type !== 'ADMIN'

    return (
        <Card title={t('manage')}>
            <Row justify="center">
                <Col span={8}>
                    <Form form={form} initialValues={account} layout="vertical">
                        <AccountForm
                            initialValues={account}
                            disabled={disabled}
                            checkEmailIsUnique={checkEmailIsUnique}
                            requirePhoneNumber={requirePhoneNumber}
                            requireTitle={requireTitle}
                        />
                        <Form.Item>
                            <div className={styles.controls}>
                                <Space>
                                    {allowCancel ? (
                                        <ActionButton
                                            disabled={isUpdating}
                                            size="middle"
                                            type="default"
                                            onClick={onCancel}
                                        >
                                            {t('cancel')}
                                        </ActionButton>
                                    ) : null}

                                    <ActionButton inProgress={isUpdating} size="middle" onClick={onUpdate}>
                                        {t('update')}
                                    </ActionButton>
                                </Space>
                            </div>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Card>
    )
}

const useManageAccountFormStyles = createUseStyles({
    controls: {
        textAlign: 'right',
    },
})
