import { Input } from 'antd'
import { Rule } from 'antd/lib/form'
import iban from 'iban'
import IMask from 'imask'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { Field } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

const useIBANValueTranslation = createUseTranslation({
    FR: {
        invalidFormat: `L'IBAN n'est pas au bon format`,
    },
    EN: {
        invalidFormat: 'The IBAN is not in the correct format',
    },
})

type IBANValueFormItemProps = {
    field: Field
}

export const IBANValueFormItem: FC<IBANValueFormItemProps> = ({ field }) => {
    const commonRules = useCommonRules()
    const { t } = useIBANValueTranslation()

    const ibanValueRules = useMemo<Rule[]>(
        () => [
            ...commonRules,
            {
                validator: async (_, value: string) =>
                    new Promise<void>((resolve, reject) => {
                        if (value === undefined || value.trim().length === 0 || iban.isValid(value)) {
                            resolve()
                        } else {
                            reject(t('invalidFormat'))
                        }
                    }),
            },
        ],
        [commonRules, t]
    )

    const mask = useMemo(
        () =>
            IMask.createMask({
                mask: '[#### #### #### #### #### #### #### #### ##]',
                definitions: {
                    '#': /[A-Z0-9]/,
                },
            }),
        []
    )

    const normalize = useCallback(
        (val: string) => {
            const normal = val.trim().toUpperCase().replaceAll(/\s/g, '')
            mask.resolve(normal)
            return mask.value
        },
        [mask]
    )

    return (
        <FormItem field={field} rules={ibanValueRules} validateFirst={true} normalize={normalize}>
            <Input disabled={field.lockedDueToSelfRepresentation} />
        </FormItem>
    )
}
