import { useEffect } from 'react'
import { atom, useRecoilCallback, useRecoilValue } from 'recoil'
import { BehaviorSubject } from 'rxjs'
import { z } from 'zod'

import { axios } from '@publica/common'
import { booleanStringSchema } from '@publica/schemas'

type ConfigLoaded = {
    type: 'configLoaded'
    config: BaseConfig
}

export const configEvents = new BehaviorSubject<ConfigLoaded | undefined>(undefined)

const envSchema = z
    .string()
    .transform(val => (val === 'production' || val === 'prod' ? 'prod' : 'dev'))
    .default('prod')

export const baseAutoEnvSchema = z
    .object({
        APP_API_HOST: z.string(),
        APP_ENV: envSchema,
        APP_TRACE: booleanStringSchema.default('off'),
        APP_TRACE_COLLECTOR: z.string().optional(),
        APP_VERSION: z.string(),
    })
    .catchall(z.unknown())
    .transform(({ APP_API_HOST, APP_ENV, APP_TRACE, APP_TRACE_COLLECTOR, APP_VERSION, ...other }) => ({
        apiHost: APP_API_HOST,
        environment: APP_ENV,
        trace: APP_TRACE,
        traceCollector: APP_TRACE_COLLECTOR,
        version: APP_VERSION,
        ...other,
    }))

export type BaseConfig = z.infer<typeof baseAutoEnvSchema> & { [key: string]: unknown }

export const baseConfigurationParser = (data: unknown): BaseConfig => baseAutoEnvSchema.parse(data)

const loadConfiguration = async () => loadConfigurationWithParser(baseConfigurationParser)

const loadConfigurationWithParser = async <C extends BaseConfig>(parser: (data: unknown) => C): Promise<C> => {
    const raw = (await axios.get('/__autoenv')).data
    const config = parser(raw)

    configEvents.next({
        type: 'configLoaded',
        config,
    })

    return config
}

let config: Promise<BaseConfig> | undefined

export const get = async () => {
    if (config === undefined) {
        config = loadConfiguration()
    }

    return config
}

export const configState = atom<BaseConfig>({
    key: 'config',
    default: loadConfiguration(),
})

export const useConfig = () => useRecoilValue(configState)

const remoteVersionState = atom<string>({
    key: 'remoteVersion',
    default: loadConfiguration().then(({ version }) => version),
})

export const useVersion = () => {
    const remoteVersion = useRecoilValue(remoteVersionState)

    const refreshRemoteVersion = useRecoilCallback(({ set }) => async () => {
        const { version } = await loadConfiguration()
        set(remoteVersionState, version)
    })

    useEffect(() => {
        const intervalId = setInterval(() => {
            void refreshRemoteVersion()
        }, 60000)
        return () => clearInterval(intervalId)
    }, [refreshRemoteVersion])

    return {
        remoteVersion,
        localVersion: __VERSION__,
        newVersion: remoteVersion > __VERSION__,
    }
}
