import { Space } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

export const ControlStrip: FC = ({ children }) => {
    const styles = utils.useControlsStyles()

    return (
        <div className={styles.controls}>
            <Space>{children}</Space>
        </div>
    )
}
