import sortBy from 'lodash/sortBy'
import { DateTime } from 'luxon'

import type { DocumentFile, DocumentFileType, DocumentStatus } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'
import { DocumentSignatory, ParticipantDocumentSignatory, documentSignatorySchema } from '@publica/schemas'
import { lang } from '@publica/utils'

type WithDocumentStatus = {
    status: DocumentStatus
}

export const documentIsGenerated = (e: WithDocumentStatus): boolean =>
    e.status === 'READY' || e.status === 'PUBLISHED' || e.status === 'AWAITING_SIGNATURE' || e.status === 'SIGNED'

export const signatoriesAreValidForDocument = (
    expectedNumberOfSignatories: number,
    signatories: DocumentSignatory[],
    participant?: ParticipantDocumentSignatory
): boolean => {
    if (expectedNumberOfSignatories !== signatories.length) {
        return false
    }

    if (participant !== undefined) {
        if (
            participant.requiresSMSAuthentication &&
            participant.powerOfAttorney !== undefined &&
            participant.powerOfAttorney.smsAuthentication === undefined
        ) {
            return false
        }
    }

    return signatories.every(signatory => {
        const result = documentSignatorySchema.safeParse(signatory)

        if (!result.success) {
            return false
        }

        return true
    })
}

const documentFileNameSuffixes: Record<KnownLocale, Record<DocumentFileType, string>> = {
    FR: {
        ORIGINAL: '',
        CERTIFICATE: '(Certificat)',
        SIGNED: '(Signé)',
    },
    EN: {
        ORIGINAL: '',
        CERTIFICATE: '(Certificate)',
        SIGNED: '(Signed)',
    },
}

export const documentFileName = (
    documentFile: Pick<DocumentFile, 'format' | 'type' | 'name'>,
    locale: KnownLocale,
    createdAt?: DateTime
): string => {
    let ext: string

    const { format, name, type } = documentFile

    switch (format) {
        case 'WORD':
            ext = 'docx'
            break
        case 'PDF':
            ext = 'pdf'
            break
        case 'ZIP':
            ext = 'zip'
            break
        default:
            throw lang.NotExhaustedError(format)
    }

    let completeName = name

    switch (type) {
        case 'ORIGINAL':
            break
        case 'CERTIFICATE':
            completeName = `${completeName} - ${documentFileNameSuffixes[locale][type]}`
            break
        case 'SIGNED':
            completeName = `${completeName} - ${documentFileNameSuffixes[locale][type]}`
            break
        default:
            throw lang.NotExhaustedError(type)
    }

    if (createdAt === undefined) {
        return `${completeName}.${ext}`
    }

    return `${completeName} - ${createdAt.toISO()}.${ext}`
}

export const documentFileTypeDisplayOrder = ['SIGNED', 'CERTIFICATE', 'ORIGINAL'] as const

export const sortDocumentFilesByType = <T extends { type: DocumentFileType }>(documentFiles: T[]) =>
    sortBy(documentFiles, documentFile => documentFileTypeDisplayOrder.indexOf(documentFile.type))

export const documentFileDownloadMenuItemKeys = {
    ALL: 'ALL',
    ORIGINAL: 'ORIGINAL',
    SIGNED: 'SIGNED',
    CERTIFICATE: 'CERTIFICATE',
} as const

export type DocumentFileDownloadMenuItemKeys = keyof typeof documentFileDownloadMenuItemKeys

export type CarbonCopyRecipient = {
    name: string
    email: string
}
