import { useMemo } from 'react'

export type PageInfo = {
    current: number
    pageSize: number
    total: number
}

export const usePaginationConfig = (pageInfo: PageInfo | undefined) =>
    useMemo<PageInfo>(
        () => ({
            current: (pageInfo?.current ?? 0) + 1,
            pageSize: pageInfo?.pageSize ?? 10,
            total: pageInfo?.total ?? 0,
        }),
        [pageInfo]
    )
