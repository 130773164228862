import baseI18n, { TOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import isFunction from 'lodash/isFunction'
import uniqueId from 'lodash/uniqueId'
import { useCallback, useMemo } from 'react'
import { useTranslation as baseUseTranslation, initReactI18next, useTranslation } from 'react-i18next'
import { BehaviorSubject, distinctUntilChanged } from 'rxjs'

import {
    KnownLocale,
    LocalizedString,
    availableLocaleTags,
    defaultLocale,
    isKnownLocale,
    resolveLocalizedString,
} from '@publica/locales'
import { userEvents } from '@publica/ui-common-auth'
import { logger } from '@publica/ui-common-logger'

export const i18n = baseI18n.createInstance()

type I18nEvents = {
    type: 'changed'
    locale: KnownLocale
}

const localeChangeSubject = new BehaviorSubject<I18nEvents>({
    type: 'changed',
    locale: defaultLocale,
})

export const localeChangeEvents = localeChangeSubject.pipe(distinctUntilChanged())

const localStorageLocaleKey = 'locale'

userEvents.on('userChanged', async ({ user }) => {
    if (user !== undefined) {
        logger.log(`User changed, setting locale to ${user.locale}`)
        window.localStorage.setItem(localStorageLocaleKey, user.locale)
        await i18n.changeLanguage(user.locale)
    }
})

i18n.on('languageChanged', locale => {
    if (isKnownLocale(locale)) {
        localeChangeSubject.next({
            type: 'changed',
            locale,
        })
    }
})

type CommonKeys =
    | 'back'
    | 'login'
    | 'operation'
    | 'cancel'
    | 'download'
    | 'created'
    | 'close'
    | 'view'
    | 'update'
    | 'create'
    | 'notFound'

const commonResources: Record<KnownLocale, Record<'common', Record<CommonKeys, string>>> = {
    FR: {
        common: {
            back: 'Retour',
            login: 'Se connecter',
            operation: 'Opération',
            cancel: 'Annuler',
            download: 'Télécharger',
            created: 'Créé',
            view: 'Voir',
            close: 'Fermer',
            update: 'Mettre à jour',
            create: 'Créer',
            notFound: 'Page non trouvée',
        },
    },
    EN: {
        common: {
            back: 'Back',
            login: 'Log in',
            operation: 'Operation',
            cancel: 'Cancel',
            download: 'Download',
            created: 'Created',
            view: 'View',
            close: 'Close',
            update: 'Update',
            create: 'Create',
            notFound: 'Page not found',
        },
    },
}

void i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: __DEBUG__,

        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],

            // keys or params to lookup language from
            lookupQuerystring: 'locale',
            lookupCookie: 'locale',
            lookupLocalStorage: localStorageLocaleKey,
            lookupSessionStorage: 'locale',
        },

        resources: commonResources,

        supportedLngs: availableLocaleTags,
        fallbackLng: defaultLocale,
        fallbackNS: 'common',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

        saveMissing: true,
        missingKeyHandler(lngs, ns, key, fallbackValue) {
            logger.warn(`Missing translation key ${ns}.${key} for ${lngs.join(', ')}`, {
                payload: {
                    fallbackValue,
                    type: 'translation',
                },
            })
        },
    })

export type TranslationsFor<K extends string> = Record<KnownLocale, Record<K, string>>

type UseTranslations<K extends string> = () => {
    t: I18TFunc<WithoutPluralSuffix<K | CommonKeys>>
    i18n: typeof i18n
}

export function createUseTranslation<K extends string = CommonKeys>(
    translations?: TranslationsFor<K>
): UseTranslations<K>

export function createUseTranslation<V extends Record<string, string>, K extends string = CommonKeys>(
    variables: () => V,
    translations: (variables: V) => TranslationsFor<K>
): UseTranslations<K>

export function createUseTranslation<V extends Record<string, string>, K extends string = CommonKeys>(
    translationsOrVariables?: TranslationsFor<K> | (() => V),
    translations?: (variables: V) => TranslationsFor<K>
): UseTranslations<K> {
    const ns = `i18n-ns-${uniqueId()}`

    return () => {
        let translationsByKey: TranslationsFor<K> | undefined

        if (translationsOrVariables === undefined) {
            if (translations !== undefined) {
                throw new Error(`Incorrect usage of createUseTranslation`)
            }
        } else if (isFunction(translationsOrVariables)) {
            if (translations === undefined) {
                throw new Error(`Incorrect usage of createUseTranslation`)
            }

            translationsByKey = translations(translationsOrVariables())
        } else {
            translationsByKey = translationsOrVariables
        }

        // We use useMemo so that it's executed immediately
        useMemo(() => {
            if (translationsByKey !== undefined) {
                for (const locale in translationsByKey) {
                    if (isKnownLocale(locale)) {
                        i18n.addResources(locale, ns, translationsByKey[locale])
                    }
                }
            }
        }, [translationsOrVariables, translations])

        const { t, ...other } = baseUseTranslation(ns)

        return { t: t as I18TFunc<WithoutPluralSuffix<K | CommonKeys>>, ...other }
    }
}

type PluralSuffixes = 'one' | 'other'
type WithoutPluralSuffix<T extends string> = T extends `${infer S}_${PluralSuffixes}` ? S : T

export const useCurrentLocale = () => {
    const { i18n } = useTranslation()
    const currentLocale = i18n.language

    return validLocaleOrDefault(currentLocale)
}

export const getCurrentLocale = () => {
    return validLocaleOrDefault(i18n.language)
}

export const useLocalizedStringResolver = () => {
    const locale = useCurrentLocale()
    return useCallback(
        <T extends LocalizedString | undefined>(string: T): T extends LocalizedString ? string : string | undefined =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            string === undefined ? (undefined as any) : resolveLocalizedString(string, locale),
        [locale]
    )
}

const validLocaleOrDefault = (locale: string) => {
    if (isKnownLocale(locale)) {
        return locale
    } else {
        return defaultLocale
    }
}

export type I18TFunc<K extends string, TInterpolationMap extends object = { [key: string]: unknown }> = (
    keys: K | K[],
    options?: TOptions<TInterpolationMap> | string
) => string
