import {
    ApolloClient,
    ApolloLink,
    ApolloProvider as BaseApolloProvider,
    DefaultContext,
    HttpLink,
    InMemoryCache,
    from,
} from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { FunctionsMap, withScalars } from 'apollo-link-scalars'
import { GraphQLFormattedError } from 'graphql'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import { suspend } from 'suspend-react'

import { schema } from '@publica/api-graphql'
import { UserErrorKey, getGraphQLUserErrorKey, platformHeaders } from '@publica/common'
import { apiEndpointsWithHost } from '@publica/endpoints'
import { DateTimeScalar } from '@publica/graphql'
import { SpanKind, withSpan } from '@publica/trace'
import { useAuthState } from '@publica/ui-common-auth'
import { logger } from '@publica/ui-common-logger'
import { FC, NetworkError, useConfig } from '@publica/ui-common-utils'

if (__DEBUG__) {
    // Adds messages only in a dev environment
    loadDevMessages()
    loadErrorMessages()
}

const operationNameHeader = 'x-graphql-operation'

const tracingFetch: typeof fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
    const headers = init?.headers
    let operationName: string | undefined | null

    if (headers !== undefined) {
        if (isArray(headers)) {
            const pair = headers.find(([key]) => key === operationNameHeader)
            operationName = (pair ?? [])[1]
        } else if (headers instanceof Headers) {
            operationName = headers.get(operationNameHeader)
        } else {
            operationName = headers[operationNameHeader]
        }
    }

    operationName = operationName ?? 'Unknown'

    return withSpan(
        `GraphQL:${operationName}`,
        async () => {
            return fetch(input, init)
        },
        {
            kind: SpanKind.CLIENT,
            attributes: {
                operationName,
            },
        }
    )
}

const typesMap: FunctionsMap = {
    DateTime: new DateTimeScalar(),
}

type Context = {
    shouldForwardError?: ErrorMatcher
} & DefaultContext

export const withContext = (ctx: Context) => ctx

export const forwardUserErrors =
    (keys?: UserErrorKey[]): ErrorMatcher =>
    error => {
        const key = getGraphQLUserErrorKey(error)

        if (key !== undefined) {
            if (keys === undefined) {
                return true
            }

            return keys.includes(key)
        }

        return false
    }

export type ErrorMatcher<E extends GraphQLFormattedError = GraphQLFormattedError> = (error: E) => boolean

const getErrorMatcherFromContext = (ctx: Context): ErrorMatcher | undefined => {
    return ctx.shouldForwardError
}

const errorPathAsString = (path: Readonly<(string | number)[]>): string =>
    reduce(path, (currPath, el) => currPath + (isString(el) ? `->${el}` : `[${el}]`), '(root)')

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext()
    const shouldForwardError = getErrorMatcherFromContext(context) ?? (() => false)

    if (graphQLErrors !== undefined && graphQLErrors.length > 0) {
        for (const error of graphQLErrors) {
            const { message, path } = error
            const errorPath = errorPathAsString(path ?? [])

            const errorMessage = `GraphQL Error: ${message} @ ${errorPath}`

            if (!shouldForwardError(error)) {
                throw new Error(errorMessage, {
                    cause: error,
                })
            } else {
                logger.warn(errorMessage)
            }
        }
    }

    if (!isNil(networkError)) {
        if (!shouldForwardError(networkError)) {
            throw new NetworkError(networkError.message)
        } else {
            logger.warn(networkError.message)
        }
    }
})

export type CreateApolloClientOptions = {
    uri: string
    getToken: () => Promise<string>
    cache?: InMemoryCache
}

export const createApolloClient = async ({ uri, getToken, cache }: CreateApolloClientOptions) => {
    const retryLink = new RetryLink({
        attempts: {
            max: 30,

            retryIf: error => {
                const statusCode = (error as { statusCode?: number }).statusCode

                if (statusCode !== undefined) {
                    if (statusCode >= 500) {
                        return true
                    }
                    return false
                }

                return true
            },
        },
    })

    // Handle custom scalars
    const scalarLink = withScalars({ schema, typesMap })

    // Token
    const authLink = setContext(async operation => {
        const token = await getToken()

        return {
            headers: {
                authorization: `Bearer ${token}`,
                // Push the operation name into a header for tracing
                [operationNameHeader]: operation.operationName,
            },
        }
    })

    // Terminating link
    const httpLink = new HttpLink({ uri, headers: platformHeaders, fetch: tracingFetch })

    // The cast is important, due to a type issue
    // https://github.com/apollographql/apollo-client/issues/10146
    const links = [errorLink, retryLink, scalarLink, authLink, httpLink] as ApolloLink[]

    return new ApolloClient({
        link: from(links),
        cache: cache ?? new InMemoryCache(),
        connectToDevTools: __DEBUG__,
    })
}

type ApolloProviderProps = {
    cache?: InMemoryCache
}

export const ApolloProvider: FC<ApolloProviderProps> = ({ children, cache }) => {
    const config = useConfig()
    const { state } = useAuthState()

    const client = suspend(
        async () =>
            createApolloClient({
                uri: apiEndpointsWithHost(config.apiHost).graphql(),
                getToken: async () => (await state.getAccountToken()).token.accessToken,
                cache,
            }),
        [cache, config.apiHost]
    )

    return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>
}
