import { Space } from 'antd'

import { FC } from '@publica/ui-common-utils'

import { Account } from '../types'
import { EditAccountAction } from './EditAccountAction'

type AccountActionsProps = {
    account: Account
}

export const AccountActions: FC<AccountActionsProps> = ({ account }) => {
    return (
        <Space>
            <EditAccountAction account={account} />
        </Space>
    )
}
