import { Form, Input } from 'antd'
import debounce from 'lodash/debounce'
import { useCallback, useMemo } from 'react'

import { AccountTitle, Locale } from '@publica/api-graphql'
import { languagesLookup, titlesLookup } from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'

import { LookupSelect } from '../LookupSelect'
import { PhoneInput, PhoneNumber, usePhoneValidationRules } from '../PhoneInput'

export type AccountFormValues = {
    title?: AccountTitle | null
    firstName: string
    lastName: string
    email: string
    phoneNumber?: PhoneNumber | null
    locale: Locale
}

type AccountFormInputValues = AccountFormValues & { phoneNumber?: string | AccountFormValues['phoneNumber'] }

type AccountFormDisabled = Partial<Record<keyof AccountFormValues, boolean>>

const useAccountsFormTranslation = createUseTranslation({
    FR: {
        accountEmailAlreadyUsed: 'Cet email est déjà utilisé',
        title: 'Civilité',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        email: 'Email',
        locale: 'Langue',
        phoneNumber: 'Numéro de téléphone portable',
    },
    EN: {
        accountEmailAlreadyUsed: 'This email address is already used',
        title: 'Title',
        firstName: 'First name',
        lastName: 'Surname',
        email: 'Email',
        locale: 'Language',
        phoneNumber: 'Mobile phone number',
    },
})

type AccountFormProps = {
    initialValues?: AccountFormInputValues
    checkEmailIsUnique: (email: string) => Promise<boolean>
    requirePhoneNumber?: boolean
    requireTitle?: boolean
    disabled?: AccountFormDisabled
}

export const AccountForm: FC<AccountFormProps> = ({
    checkEmailIsUnique,
    disabled,
    initialValues,
    requirePhoneNumber = true,
    requireTitle = true,
}) => {
    const { t } = useAccountsFormTranslation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkAccountEmail = useCallback(
        debounce((email: string, callback: (error?: string) => void): void => {
            void (async () =>
                checkEmailIsUnique(email).then(isUnique => {
                    if (isUnique) {
                        callback()
                    } else {
                        callback(t('accountEmailAlreadyUsed', { email }))
                    }
                }))()
        }, 500),
        [checkEmailIsUnique]
    )

    const rules = useCommonRules()
    const phoneValidationRules = usePhoneValidationRules()

    const validationRules = useMemo<FormRules<AccountFormValues>>(
        () => ({
            locale: rules.required,
            firstName: rules.required,
            lastName: rules.required,
            title: requireTitle ? rules.required : [],
            email: [
                ...rules.requiredEmail,
                {
                    validator: async (_, email: string) =>
                        new Promise<void>((resolve, reject) => {
                            // Don't bother checking if the email hasn't been changed
                            if (
                                initialValues?.email !== undefined &&
                                email.trim().toLowerCase() === initialValues.email.trim().toLowerCase()
                            ) {
                                resolve()
                            }

                            checkAccountEmail(email, error => {
                                if (error !== undefined) {
                                    reject(error)
                                } else {
                                    resolve()
                                }
                            })
                        }),
                },
            ],
            phoneNumber: [...phoneValidationRules, ...(requirePhoneNumber ? rules.required : [])],
        }),
        [
            rules.required,
            rules.requiredEmail,
            requireTitle,
            phoneValidationRules,
            requirePhoneNumber,
            initialValues?.email,
            checkAccountEmail,
        ]
    )

    return (
        <>
            <Form.Item name="title" label={t('title')} required hasFeedback rules={validationRules.title}>
                <LookupSelect lookup={titlesLookup} disabled={disabled?.title} />
            </Form.Item>
            <Form.Item name="firstName" label={t('firstName')} required hasFeedback rules={validationRules.firstName}>
                <Input disabled={disabled?.firstName} />
            </Form.Item>
            <Form.Item name="lastName" label={t('lastName')} required hasFeedback rules={validationRules.lastName}>
                <Input disabled={disabled?.lastName} />
            </Form.Item>
            <Form.Item name="email" label={t('email')} required hasFeedback rules={validationRules.email}>
                <Input disabled={disabled?.email} />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label={t('phoneNumber')}
                required
                hasFeedback
                rules={validationRules.phoneNumber}
            >
                <PhoneInput disabled={disabled?.phoneNumber} />
            </Form.Item>
            <Form.Item name="locale" label={t('locale')} required hasFeedback rules={validationRules.locale}>
                <LookupSelect lookup={languagesLookup} disabled={disabled?.locale} />
            </Form.Item>
        </>
    )
}
