import { z } from 'zod'

import { localeSchema } from '@publica/locales'
import { accountTypeSchema, titleSchema } from '@publica/lookups'

import { emailSchema } from './email'

export const createSignInCodeRequestSchema = z.object({
    email: emailSchema,
})

export type CreateSignInCodeRequest = z.infer<typeof createSignInCodeRequestSchema>

export const getTokenRequestSchema = z.object({
    email: emailSchema,
    signInCode: z.number(),
})

export type GetTokenRequest = z.infer<typeof getTokenRequestSchema>

export const getTokenResponseSchema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
})

export type GetTokenResponse = z.infer<typeof getTokenResponseSchema>

export const refreshTokenRequestSchema = z.object({
    refreshToken: z.string(),
})

export type RefreshTokenRequest = z.infer<typeof refreshTokenRequestSchema>

export const refreshTokenResponseSchema = getTokenResponseSchema

export type RefreshTokenResponse = z.infer<typeof refreshTokenResponseSchema>

export const getAccountResponseSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    locale: localeSchema,
    title: titleSchema.nullable(),
    phoneNumber: z.string().nullable(),
    type: accountTypeSchema,
})

export type GetAccountResponse = z.infer<typeof getAccountResponseSchema>
