import { FC } from '@publica/ui-common-utils'
import { ControlStrip } from '@publica/ui-web-components'

import { AddAccountControl } from './AddAccountControl'

export const AccountsControls: FC = () => {
    return (
        <ControlStrip>
            <AddAccountControl />
        </ControlStrip>
    )
}
