import { FC } from '@publica/ui-common-utils'
import { OperationTable } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationsQuery } from '../../../../data'
import { OperationControls } from './OperationControls'

export const OperationIndex: FC = () => {
    const { loading, data } = useGetOperationsQuery({ pollInterval: usePollingRate() })

    const operations = data?.operations ?? []

    return (
        <>
            <OperationControls />
            <OperationTable loading={loading} operations={operations} />
        </>
    )
}
