import isArray from 'lodash/isArray'
import { useCallback } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { RemoteSelect } from '@publica/ui-web-components'

import { useSearchOperationParticipantsLazyQuery } from '../../../data'

type Participant = {
    id: string
    info: {
        code: string
        name: string
    }
}

type ParticipantSearchQuery = {
    code?: string[]
    name?: string[]
}

type ParticipantSearchProps = {
    operation: {
        id: string
    }
    value?: Participant
    onChange?: (value: Participant | undefined) => void
    disabled?: boolean
}

export const ParticipantSearch: FC<ParticipantSearchProps> = ({ onChange, value, disabled, operation }) => {
    const [searchOperationParticipants] = useSearchOperationParticipantsLazyQuery()
    const { t } = useParticipantSearchTranslation()

    const searchParticipants = useCallback<(query: ParticipantSearchQuery) => Promise<Participant[]>>(
        async (query: ParticipantSearchQuery) => {
            let page = 0
            const pageSize = 20

            let hasMorePages = true

            const participants: Participant[] = []

            while (hasMorePages) {
                const results = await searchOperationParticipants({
                    variables: {
                        operationId: operation.id,
                        page,
                        pageSize,
                        filters: {
                            code: query.code,
                            name: query.name,
                        },
                    },
                    fetchPolicy: 'no-cache',
                }).then(result => result.data?.operation?.participantsConnection)

                if (results === undefined) {
                    break
                }

                const { pageInfo, edges } = results

                participants.push(...edges.map(edge => edge.node))
                hasMorePages = pageInfo.total - (pageInfo.current * pageInfo.pageSize + edges.length) > 0
                page++
            }

            return participants
        },
        [operation.id, searchOperationParticipants]
    )

    const onChangeParticipant = useCallback<
        (value: unknown, option: ParticipantSearchResult | ParticipantSearchResult[] | undefined) => void
    >(
        (_, option) => {
            // We don't handle multiple values
            if (option === undefined) {
                onChange?.(undefined)
            } else if (!isArray(option)) {
                onChange?.(option.participant)
            }
        },
        [onChange]
    )

    const fetchData = useCallback<(term: string) => Promise<ParticipantSearchResult[]>>(
        async (term: string) =>
            searchParticipants({
                code: [term],
            }).then(results => results.map(participantToSearchResult)),
        [searchParticipants]
    )

    return (
        <RemoteSelect<ParticipantSearchResult>
            placeholder={t('searchPlaceholder')}
            fetchData={fetchData}
            onChange={onChangeParticipant}
            disabled={disabled}
            value={participantToSearchResult(value)}
        />
    )
}

const participantToSearchResult = <P extends Participant | undefined>(
    participant: P
): P extends undefined ? undefined : ParticipantSearchResult => {
    if (participant === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return undefined as any
    }

    const result: ParticipantSearchResult = {
        label: `${participant.info.code} - ${participant.info.name}`,
        value: participant.id,
        participant,
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return result as any
}

const useParticipantSearchTranslation = createUseTranslation({
    FR: {
        searchPlaceholder: 'Chercher participant...',
    },
    EN: {
        searchPlaceholder: 'Search for participant...',
    },
})

type ParticipantSearchResult = {
    label: string
    value: string
    participant: Participant
}
