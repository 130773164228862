import { GraphQLFormattedError, GraphQLFormattedErrorExtensions } from 'graphql'
import isObject from 'lodash/isObject'
import { z } from 'zod'

export const graphQLUserErrorCode = 'BAD_USER_INPUT'

export type GraphQLUserError = GraphQLFormattedError & {
    extensions: GraphQLFormattedErrorExtensions & {
        code: typeof graphQLUserErrorCode
        key?: UserErrorKey
    }
}

export const tryToUnwrapApolloError = (error: unknown) => {
    if (error instanceof Error && error.name === 'ApolloError') {
        return error.cause
    } else {
        return error
    }
}

export const getGraphQLUserError = (error: unknown): GraphQLUserError | undefined => {
    if (!isObject(error)) {
        return undefined
    }

    const maybeUnwrapped = tryToUnwrapApolloError(error)

    if ((maybeUnwrapped as GraphQLFormattedError).extensions?.code === graphQLUserErrorCode) {
        return maybeUnwrapped as GraphQLUserError
    }

    return undefined
}

export const userErrorKeySchema = z.enum(['ALREADY_IN_USE'])

export type UserErrorKey = z.infer<typeof userErrorKeySchema>

export const getGraphQLUserErrorKey = (error: unknown): undefined | UserErrorKey => {
    const maybeGraphQLUserError = getGraphQLUserError(error)

    if (maybeGraphQLUserError !== undefined) {
        const data = userErrorKeySchema.safeParse(maybeGraphQLUserError.extensions.key)

        if (data.success) {
            return data.data
        }
    }

    return undefined
}

export const isGraphQLUserErrorOfType = (error: unknown, key: UserErrorKey) => {
    return getGraphQLUserError(error)?.extensions.key === key
}
