import { Empty, Select, Spin } from 'antd'
import type { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import { useMemo, useRef, useState } from 'react'

export type RemoteSelectProps<O extends DefaultOptionType | BaseOptionType = DefaultOptionType> = Omit<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SelectProps<any, O>,
    'options' | 'children'
> & {
    fetchData: (search: string) => Promise<O[]>
    debounceTimeout?: number
}

export const RemoteSelect = <O extends DefaultOptionType | BaseOptionType = DefaultOptionType>({
    fetchData,
    debounceTimeout = 800,
    ...props
}: RemoteSelectProps<O>) => {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState<O[]>([])
    const [isSearching, setIsSearching] = useState(false)
    const fetchRef = useRef(0)

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            if (value.trim() === '') {
                setOptions([])
                setIsSearching(false)
                return
            }

            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setFetching(true)
            setIsSearching(true)

            void fetchData(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return
                }

                setOptions(sortBy(newOptions, o => o.label))
                setFetching(false)
            })
        }

        return debounce(loadOptions, debounceTimeout)
    }, [fetchData, debounceTimeout])

    const empty = useMemo(() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />, [])

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? smallSpinner : isSearching ? empty : null}
            {...props}
            options={options}
            showSearch
            loading={fetching}
        />
    )
}

const smallSpinner = <Spin size="small" />
