import { assert } from '@publica/utils'

type ParticipantWithPossiblyUndefinedRepresentative = {
    legalEntity: {
        representative?: object | null
    }
}

export const ensureParticipantLegalEntityHasRepresentative = <P extends ParticipantWithPossiblyUndefinedRepresentative>(
    participant: P
) => {
    const { representative, ...other } = participant.legalEntity

    assert.defined(representative)

    return {
        ...participant,
        legalEntity: {
            ...other,
            representative,
        },
    }
}
