import { FC } from '@publica/ui-common-utils'

import { EmailSet } from '../../types'
import { EmailSetArchiveControl } from './ArchiveEmailSetAction'
import { EmailSetRestoreControl } from './RestoreEmailSetAction'

type EmailSetActionsProps = {
    emailSet: EmailSet
}

export const EmailSetActions: FC<EmailSetActionsProps> = ({ emailSet }) => {
    if (!emailSet.isArchived) {
        return <EmailSetArchiveControl emailSet={emailSet} />
    }
    return <EmailSetRestoreControl emailSet={emailSet} />
}
