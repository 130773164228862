import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form } from 'antd'
import { useCallback } from 'react'

import { forwardUserErrors, withContext } from '@publica/ui-common-network'
import { FC } from '@publica/ui-common-utils'
import { AccountForm, AccountFormValues } from '@publica/ui-web-components'
import { ControlledForm, ControlledFormWithElement, useControlledForm } from '@publica/ui-web-utils'

import { useCheckAccountEmailLazyQuery, useUpdateAccountMutation } from '../../../../../../../data'
import { Account } from '../../../types'

type EditAccountFormProps = {
    account: Account
    form: ControlledForm<string | undefined, EditAccountFormValues>
}

const EditAccountForm: FC<EditAccountFormProps> = ({ form, account }) => {
    const [checkAccountEmailQuery] = useCheckAccountEmailLazyQuery()

    const checkAccountEmailIsUnique = useCallback(
        async (email: string) => {
            const { data } = await checkAccountEmailQuery({
                variables: {
                    email,
                },
            })
            return data?.accountByEmail?.id === undefined
        },
        [checkAccountEmailQuery]
    )

    return (
        <Form form={form.form} layout="vertical" initialValues={account}>
            <AccountForm
                checkEmailIsUnique={checkAccountEmailIsUnique}
                initialValues={account}
                requirePhoneNumber={false}
            />
        </Form>
    )
}

type EditAccountFormValues = AccountFormValues

type EditAccountFormOptions = {
    refetchQueries?: InternalRefetchQueriesInclude
}

export const useEditAccountForm = (
    account: Account,
    options?: EditAccountFormOptions
): ControlledFormWithElement<string | undefined, EditAccountFormValues> => {
    const [updateAccountMutation, { loading }] = useUpdateAccountMutation()

    const submit = useCallback(
        async (values: EditAccountFormValues) => {
            const { phoneNumber, ...accountValues } = values

            return updateAccountMutation({
                variables: {
                    id: account.id,
                    account: {
                        ...accountValues,
                        phoneNumber: phoneNumber?.validNumber,
                    },
                },
                context: withContext({
                    shouldForwardError: forwardUserErrors(['ALREADY_IN_USE']),
                }),
                refetchQueries: options?.refetchQueries,
            }).then(({ data }) => data?.updateAccount?.id)
        },
        [account.id, options?.refetchQueries, updateAccountMutation]
    )

    return useControlledForm({
        render: form => <EditAccountForm form={form} account={account} />,
        submit,
        loading,
    })
}
