import { FormOutlined } from '@ant-design/icons'
import { Alert, Card, Modal } from 'antd'
import { Form } from 'antd'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { operationIsMutable } from '@publica/common'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC, removeTypeName, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, FilterColumnType, FilterTable, LocalizedStringInput } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { assert } from '@publica/utils'

import * as graphql from '../../../../../data'
import {
    GetOperationFieldsDocument,
    useCreateFormSectionMutation,
    useSetFormSectionOrderMutation,
    useUpdateFormSectionMutation,
} from '../../../../../data'
import { LocalizedString } from '../types'

type Operation = Pick<graphql.Operation, 'id' | 'status'>

type FormSection = Pick<graphql.FormSection, 'id' | 'name' | 'position'>

type FormSectionsProps = {
    formSections: FormSection[]
    operation: Operation
}

const useStyles = createUseStyles({
    message: {
        marginBottom: 15,
    },
})

const useFormSectionsTranslation = createUseTranslation({
    EN: {
        title: 'Form sections',
        message: 'The form displayed to participants is divided into sections.',
    },
    FR: {
        title: 'Sections du formulaire',
        message:
            'Le formulaire présenté aux participants est divisé en sections, afin de rendre le formulaire plus érgonomique.',
    },
})

export const FormSections: FC<FormSectionsProps> = ({ operation, formSections }) => {
    const styles = useStyles()
    const { t } = useFormSectionsTranslation()

    return (
        <Card title={t('title')}>
            <Alert message={t('message')} className={styles.message} />
            <FormSectionsControls operation={operation} />
            <FormSectionsTable operation={operation} formSections={formSections} />
        </Card>
    )
}

type FormSectionsControls = {
    operation: Operation
}

const addFormSectionIcon = <FormOutlined />

const useFormSectionsControlsTranslation = createUseTranslation({
    EN: {
        add: 'Add section',
    },
    FR: {
        add: 'Ajouter une section',
    },
})

const FormSectionsControls: FC<FormSectionsControls> = ({ operation }) => {
    const mutable = operationIsMutable(operation)
    const styles = utils.useControlsStyles()
    const [open, setOpen] = useState(false)
    const { t } = useFormSectionsControlsTranslation()

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <div className={styles.controls}>
                <ActionButton size="middle" onClick={onClick} disabled={!mutable} icon={addFormSectionIcon}>
                    {t('add')}
                </ActionButton>
            </div>
            <FormSectionForm
                title={t('add')}
                actionText={t('create')}
                open={open}
                onClose={onClose}
                operationId={operation.id}
            />
        </>
    )
}

type FormSectionsTableProps = {
    operation: Operation
    formSections: FormSection[]
}

const useFormSectionsTableTranslation = createUseTranslation({
    EN: {
        section: 'Section',
        actions: 'Actions',
    },
    FR: {
        section: 'Section',
        actions: 'Actions',
    },
})

const FormSectionsTable: FC<FormSectionsTableProps> = ({ formSections, operation }) => {
    const mutable = operationIsMutable(operation)
    const [setFormSectionOrderMutation] = useSetFormSectionOrderMutation()
    const resolveLocalizedString = useLocalizedStringResolver()
    const { t } = useFormSectionsTableTranslation()

    const columns: FilterColumnType<FormSection>[] = useMemo(
        () => [
            {
                title: t('section'),
                render: (_, formSection) => resolveLocalizedString(formSection.name),
            },
            {
                title: t('actions'),
                render: (_, formSection) => (
                    <EditFormSection mutable={mutable} formSection={formSection} operation={operation} />
                ),
                align: 'right',
            },
        ],
        [mutable, operation, resolveLocalizedString, t]
    )

    const sorted = useMemo(() => sortBy(formSections, formSection => formSection.position), [formSections])

    const onSort = useAsyncCallback(
        async (formSections: FormSection[]) => {
            await setFormSectionOrderMutation({
                variables: {
                    operationId: operation.id,
                    formSectionIds: formSections.map(formSection => formSection.id),
                },
            })
        },
        [operation.id, setFormSectionOrderMutation]
    )

    return <FilterTable<FormSection> columns={columns} dataSource={sorted} rowKey="id" canDragSort onSort={onSort} />
}

type FormSectionFormValues = {
    name: LocalizedString | undefined
}

type FormSectionFormProps = {
    open: boolean
    operationId: string
    onClose: () => void
    formSection?: FormSection
    title: string
    actionText: string
}

const useFormSectionFormTranslation = createUseTranslation({
    EN: {
        name: 'Name',
    },
    FR: {
        name: 'Nom',
    },
})

const FormSectionForm: FC<FormSectionFormProps> = ({ open, onClose, operationId, title, actionText, formSection }) => {
    const isUpdate = formSection !== undefined
    const [form] = Form.useForm<FormSectionFormValues>()
    const [createFormSectionMutation] = useCreateFormSectionMutation()
    const [updateFormSectionMutation] = useUpdateFormSectionMutation()
    const { t } = useFormSectionFormTranslation()

    const close = useCallback(() => {
        form.resetFields()
        onClose()
    }, [form, onClose])

    const getFormValues = useCallback(() => {
        const { name } = form.getFieldsValue()

        assert.defined(name)

        return { name }
    }, [form])

    const create = useCallback(async () => {
        const { name } = getFormValues()

        await createFormSectionMutation({
            variables: {
                operationId,
                formSection: {
                    name,
                },
            },
            refetchQueries: [GetOperationFieldsDocument],
        })

        close()
    }, [close, createFormSectionMutation, getFormValues, operationId])

    const update = useCallback(async () => {
        const { name } = getFormValues()
        const id = formSection?.id

        assert.defined(id)

        await updateFormSectionMutation({
            variables: {
                id,
                formSection: {
                    name,
                },
            },
            refetchQueries: [GetOperationFieldsDocument],
        })

        close()
    }, [formSection?.id, close, getFormValues, updateFormSectionMutation])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        if (isUpdate) {
            await update()
        } else {
            await create()
        }
    }, [create, form, isUpdate, update])

    const initialValues = useMemo<FormSectionFormValues>(
        () => ({
            name: removeTypeName(formSection?.name),
        }),
        [formSection?.name]
    )

    return (
        <Modal open={open} onCancel={close} title={title} okText={actionText} onOk={onOk}>
            <Form form={form} layout="vertical" initialValues={initialValues}>
                <Form.Item name="name" label={t('name')} required>
                    <LocalizedStringInput path="name" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

type EditFormSectionProps = {
    mutable: boolean
    formSection: FormSection
    operation: Operation
}

const useEditFormSectionTranslation = createUseTranslation()

const EditFormSection: FC<EditFormSectionProps> = ({ mutable, formSection, operation }) => {
    const [open, setOpen] = useState(false)
    const { t } = useEditFormSectionTranslation()

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    return (
        <>
            <ActionButton disabled={!mutable} onClick={onClick}>
                {t('update')}
            </ActionButton>
            <FormSectionForm
                title={t('update')}
                actionText={t('update')}
                open={open}
                onClose={onClose}
                operationId={operation.id}
                formSection={formSection}
            />
        </>
    )
}
