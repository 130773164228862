import { TablePaginationConfig } from 'antd'
import { ColumnType } from 'antd/es/table'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import { accountTypeLookup, languagesLookup, titlesLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC, useDebouncedAsyncCallback, usePaginationConfig } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { useGetAccountsQuery } from '../../../../data'
import { AccountActions } from './AccountActions'
import { Account } from './types'

type AccountTableFilters = {
    name?: string
    email?: string
}

export const AccountsTable: FC = () => {
    const { data, loading, fetchMore } = useGetAccountsQuery()
    const locale = useCurrentLocale()

    const { t } = useAccountsTableTranslation()

    const accounts = useMemo(
        () =>
            sortBy(
                (data?.accounts?.edges ?? []).map(edge => edge.node),
                account => account.name
            ),
        [data?.accounts?.edges]
    )

    const columns = useMemo<(FilterColumnType<Account> | ColumnType<Account>)[]>(
        () => [
            {
                title: t('title'),
                render: (_, account) => {
                    if (isNil(account.title)) {
                        return ''
                    }

                    return titlesLookup.labelForKeyAndLocale(account.title, locale)
                },
            },
            {
                title: t('name'),
                key: 'name',
                render: (_, account) => account.name,
                filterValue: true,
                align: 'left',
            },
            {
                title: t('email'),
                key: 'email',
                render: (_, account) => account.email,
                filterValue: true,
            },
            {
                title: t('locale'),
                render: (_, account) => languagesLookup.labelForKeyAndLocale(account.locale, locale),
            },
            {
                title: t('type'),
                render: (_, account) => accountTypeLookup.labelForKeyAndLocale(account.type, locale),
            },
            {
                title: t('actions'),
                render: (_, account) => {
                    if (account.type === 'ADMIN') {
                        return ''
                    }

                    return <AccountActions account={account} />
                },
                align: 'right',
            },
        ],
        [locale, t]
    )

    const pagination = usePaginationConfig(data?.accounts?.pageInfo)

    const onChange = useDebouncedAsyncCallback(
        async (pagination: TablePaginationConfig, filters: AccountTableFilters) => {
            const { pageSize, current } = pagination

            const { name, email } = filters

            await fetchMore({
                variables: {
                    page: Math.max((current ?? 1) - 1, 0),
                    pageSize,
                    filters: {
                        name,
                        email,
                    },
                },
            })
        },
        [fetchMore]
    )

    return (
        <FilterTable<Account>
            dataSource={accounts}
            rowKey="id"
            loading={loading}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
        />
    )
}

const useAccountsTableTranslation = createUseTranslation({
    EN: {
        actions: 'Actions',
        name: 'Name',
        email: 'Email',
        type: 'Type',
        title: 'Title',
        locale: 'Language',
    },
    FR: {
        actions: 'Actions',
        name: 'Compte',
        email: 'Email',
        type: 'Type',
        title: 'Civlité',
        locale: 'Langue',
    },
})
