import { axios } from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'
import {
    CreateSignInCodeRequest,
    GetAccountResponse,
    GetTokenRequest,
    GetTokenResponse,
    RefreshTokenRequest,
    RefreshTokenResponse,
    getAccountResponseSchema,
    getTokenResponseSchema,
    refreshTokenResponseSchema,
} from '@publica/schemas'

import { getAuthHeaders } from '../auth'

export class AuthClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async getTokenForSignInCode(request: GetTokenRequest): Promise<GetTokenResponse> {
        return axios
            .post<unknown>(this.endpoints.auth.getTokenForSignInCode(), request)
            .then(({ data }) => getTokenResponseSchema.parse(data))
    }

    async createSignInCode(request: CreateSignInCodeRequest): Promise<void> {
        await axios.post(this.endpoints.auth.createSignInCode(), request)
    }

    async refreshToken(request: RefreshTokenRequest): Promise<RefreshTokenResponse> {
        return axios
            .post<unknown>(this.endpoints.auth.refreshToken(), request)
            .then(({ data }) => refreshTokenResponseSchema.parse(data))
    }

    async account(): Promise<GetAccountResponse> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .get<unknown>(this.endpoints.auth.account(), { headers })
            .then(({ data }) => getAccountResponseSchema.parse(data))
    }
}
