import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { forwardUserErrors, withContext } from '@publica/ui-common-network'
import { FC } from '@publica/ui-common-utils'
import { ManageAccount, ManageAccountFormValues } from '@publica/ui-web-components'

import { useCheckAccountEmailLazyQuery, useUpdateAccountMutation } from '../../../data'

export const Account: FC = () => {
    const navigate = useNavigate()

    const [checkAccountEmailQuery] = useCheckAccountEmailLazyQuery()
    const [updateAccountMutation] = useUpdateAccountMutation()

    const onCancelOrComplete = useCallback(() => {
        navigate('/')
    }, [navigate])

    const checkAccountEmailIsUnique = useCallback(
        async (email: string) => {
            const { data } = await checkAccountEmailQuery({
                variables: {
                    email,
                },
            })
            return data?.accountByEmail?.id === undefined
        },
        [checkAccountEmailQuery]
    )

    const onUpdate = useCallback(
        async (id: string, account: ManageAccountFormValues) => {
            await updateAccountMutation({
                variables: {
                    id,
                    account,
                },
                context: withContext({
                    shouldForwardError: forwardUserErrors(['ALREADY_IN_USE']),
                }),
            })
        },
        [updateAccountMutation]
    )

    return (
        <ManageAccount
            onUpdate={onUpdate}
            onCancel={onCancelOrComplete}
            checkEmailIsUnique={checkAccountEmailIsUnique}
            onComplete={onCancelOrComplete}
        />
    )
}
