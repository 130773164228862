import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const accountTypeSchema = z.enum(['ADMIN', 'PARTICIPANT'])

export type AccountType = z.infer<typeof accountTypeSchema>

const accountTypes: Record<KnownLocale, Record<AccountType, string>> = {
    FR: {
        ADMIN: 'Administrateur',
        PARTICIPANT: 'Participant',
    },
    EN: {
        ADMIN: 'Administrator',
        PARTICIPANT: 'Participant',
    },
}

export const accountTypeLookup = new LookupList(accountTypes)
