import { Form } from 'antd'
import { useMemo } from 'react'

import { useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { PhoneInput, PhoneNumber, usePhoneValidationRules } from '../../../'
import { Field } from '../../types'
import { useCommonRules } from './rules'

type PhoneValueFormItemProps = {
    field: Field
}

const phoneNumberToString = (phoneNumber: PhoneNumber | undefined): string | undefined => phoneNumber?.validNumber

export const PhoneValueFormItem: FC<PhoneValueFormItemProps> = ({ field }) => {
    const commonRules = useCommonRules()
    const phoneValidationRules = usePhoneValidationRules()
    const phoneValueRules = useMemo(
        () => [...commonRules, ...phoneValidationRules],
        [commonRules, phoneValidationRules]
    )

    const resolveLocalizedString = useLocalizedStringResolver()

    return (
        <Form.Item
            label={resolveLocalizedString(field.name)}
            key={field.key}
            hasFeedback
            name={field.key}
            rules={phoneValueRules}
            normalize={phoneNumberToString}
        >
            <PhoneInput disabled={field.lockedDueToSelfRepresentation} />
        </Form.Item>
    )
}
