import { FC } from '@publica/ui-common-utils'

import { AccountsControls } from './AccountsControls'
import { AccountsTable } from './AccountsTable'

export const Accounts: FC = () => {
    return (
        <>
            <AccountsControls />
            <AccountsTable />
        </>
    )
}
