import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { OperationStatus } from '../../../data'
import { EmailsIndex } from './EmailsIndex'
import { NewEmailSet } from './NewEmailSet'

type EmailsProps = {
    operation: {
        id: string
        status: OperationStatus
    }
}

export const Emails: FC<EmailsProps> = ({ operation }) => {
    const emailIndex = useMemo(() => <EmailsIndex operation={operation} />, [operation])
    const newEmailSet = useMemo(() => <NewEmailSet operation={operation} />, [operation])

    return (
        <Routes>
            <Route path="emails">
                <Route index element={emailIndex} />
                <Route path="new" element={newEmailSet} />
            </Route>
        </Routes>
    )
}
