import { ReactElement } from 'react'
import { Location, useLocation } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { useAuthState } from '../hooks'

export type AuthGuardProps = {
    login: ReactElement
    initializing?: ReactElement
    loading?: ReactElement
    check?: (location: Location | undefined) => boolean
}

const useLocationSafe = () => {
    // useLocation fails outside of a router context, like in Word
    // we can't conditionnally call it because it's a hook
    try {
        return useLocation()
    } catch (e) {
        return undefined
    }
}

export const AuthGuard: FC<AuthGuardProps> = ({ login, children, initializing, loading, check }) => {
    const { isAuthenticated, isAuthenticating, isInitialized } = useAuthState()
    const location = useLocationSafe()

    if (check?.(location) === true) {
        return <>{children}</>
    }

    if (!isInitialized && initializing !== undefined) {
        return initializing
    }

    if (isAuthenticating && loading !== undefined) {
        return loading
    }

    if (!isAuthenticated) {
        return login
    }

    return <>{children}</>
}
