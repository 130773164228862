import { Form } from 'antd'
import { FormItemProps as BaseFormItemProps } from 'antd/lib/form'
import { createUseStyles } from 'react-jss'

import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { Field } from '../../types'

type FormItemProps = {
    field: Field
} & Omit<BaseFormItemProps, 'hasFeedback' | 'noStyle'>

export const FormItem: FC<FormItemProps> = ({ children, field, label, name, required, ...props }) => {
    const resolveLocalizedString = useLocalizedStringResolver()

    return (
        <Form.Item label={label ?? resolveLocalizedString(field.name)} hasFeedback required={required}>
            <Form.Item name={name ?? field.key} hasFeedback key={field.key} {...props} noStyle>
                {children}
            </Form.Item>
            {field.lockedDueToSelfRepresentation ? <LockedDueToSelfRepresentation /> : null}
        </Form.Item>
    )
}

const LockedDueToSelfRepresentation: FC = () => {
    const { t } = useLockedDueToSelfRepresentationTranslation()
    const styles = useLockedDueToSelfRepresentationStyles()

    return <div className={styles.locked}>{t('locked')}</div>
}

const useLockedDueToSelfRepresentationStyles = createUseStyles({
    locked: {
        marginTop: 8,
        paddingLeft: 5,
        fontSize: '0.8em',
        color: colors.grey7,
    },
})

const useLockedDueToSelfRepresentationTranslation = createUseTranslation({
    FR: {
        locked: 'Veuillez mettre à jour vos informations de compte pour modifier cette information',
    },
    EN: {
        locked: 'Please update your account information to change this value',
    },
})
