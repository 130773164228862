import { Logger, SeverityNumber } from '@opentelemetry/api-logs'
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http'
import { BatchLogRecordProcessor, LoggerProvider } from '@opentelemetry/sdk-logs'

import { getResource } from '@publica/trace'

import type { LogLevelName, LogRecord, LogTransport } from '../types'

const defaultCollectorLogsEndpoint = 'http://localhost:4318'

export class OpenTelemetryTransport implements LogTransport {
    private readonly provider: LoggerProvider
    private readonly logger: Logger
    private visibilityChangeListener?: () => void
    private pageHideListener?: () => void

    constructor(collectorEndpoint = defaultCollectorLogsEndpoint) {
        this.provider = new LoggerProvider({
            resource: getResource(),
        })

        this.provider.addLogRecordProcessor(
            new BatchLogRecordProcessor(
                new OTLPLogExporter({
                    url: `${collectorEndpoint}/v1/logs`,
                })
            )
        )

        this.logger = this.provider.getLogger(__APP__, __VERSION__, {
            includeTraceContext: true,
        })

        if (typeof document !== 'undefined') {
            this.visibilityChangeListener = () => {
                if (document.visibilityState === 'hidden') {
                    void this.provider.forceFlush()
                }
            }

            this.pageHideListener = () => {
                void this.provider.forceFlush()
            }

            document.addEventListener('visibilitychange', this.visibilityChangeListener)
            // use 'pagehide' event as a fallback for Safari; see https://bugs.webkit.org/show_bug.cgi?id=116769
            document.addEventListener('pagehide', this.pageHideListener)
        }
    }

    log(record: LogRecord) {
        const { levelName, timestamp, context, component, message } = record
        const { labels, error } = context

        const ts = timestamp.toJSDate()

        this.logger.emit({
            timestamp: ts,
            observedTimestamp: ts,
            severityNumber: this.levelToSeverity(levelName),
            severityText: levelName,
            body: {
                message,
            },
            attributes: {
                ...labels,
                component,
                'gcp.log_name': __APP__,
            },
        })

        // Immediately send error logs
        if (error !== undefined) {
            void this.provider.forceFlush()
        }
    }

    private levelToSeverity(level: LogLevelName): SeverityNumber {
        switch (level) {
            case 'debug':
                return SeverityNumber.DEBUG
            case 'error':
                return SeverityNumber.ERROR
            case 'info':
                return SeverityNumber.INFO
            case 'warn':
                return SeverityNumber.WARN
        }
    }
}
