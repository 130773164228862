import Emittery from 'emittery'

import { Account } from './types'

type Events = {
    userChanged: {
        user: Account | undefined
    }
}

export const userEvents = new Emittery<Events>()
