import { AntDActionElement, QueryBuilderAntD } from '@react-querybuilder/antd'
import { Form } from 'antd'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import {
    Option,
    OptionGroup,
    QueryBuilder,
    Controls as QueryControls,
    Field as QueryField,
    Translations as QueryTranslations,
    RuleGroupType,
} from 'react-querybuilder'

import { KnownLocale, resolveLocalizedString } from '@publica/locales'
import { countryLookup, maritalStatusLookup, nationalityLookup, titlesLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { fieldGroupLabel, fieldGroups } from '@publica/ui-common-labels'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { icons } from '@publica/ui-web-components'
import { lang, normalizeString } from '@publica/utils'
import { GraphQLValue } from '@publica/values'

import * as graphql from '../../../data'
import './query.less'

type FilterField = Pick<graphql.Field, 'key' | 'name' | 'group' | 'position' | 'parameters'>

type Participant = Pick<graphql.Participant, 'id'> & { values: GraphQLValue[] }

type Group = Pick<graphql.Group, 'name' | 'key'>

export const useParticipantFilterQueryFilters = (fields: FilterField[], groups: Group[]): OptionGroup<QueryField>[] => {
    const fieldFilters = useFieldsAsQueryFields(fields)
    const groupFilter = useParticipantGroupOptionGroup(groups)

    return [...fieldFilters, groupFilter]
}

const useFieldsAsQueryFields = (fields: FilterField[]): OptionGroup<QueryField>[] => {
    const locale = useCurrentLocale()
    const operators = useQueryOperators()
    const addressLabels = useAddressLabels()

    return useMemo<OptionGroup<QueryField>[]>(() => {
        const fieldsByGroup = groupBy(fields, field => field.group) as Partial<
            Record<graphql.FieldGroup, FilterField[]>
        >
        const optionGroups: OptionGroup<QueryField>[] = []

        for (const group of fieldGroups) {
            const fields = fieldsByGroup[group]
            if (fields === undefined) {
                continue
            }

            optionGroups.push({
                label: fieldGroupLabel(group),
                options: flatMap(
                    sortBy(fields, field => field.position),
                    (field): QueryField[] => queryFieldsForField(field, locale, operators, addressLabels)
                ),
            })
        }

        return optionGroups
    }, [addressLabels, fields, locale, operators])
}

export const participantFilterGroupKey = '__participant_groups'

const useParticipantGroupOptionGroupTranslations = createUseTranslation({
    FR: {
        label: 'Groupes',
    },
    EN: {
        label: 'Groups',
    },
})

const useParticipantGroupOptionGroup = (groups: Group[]): OptionGroup<QueryField> => {
    const { t: queryT } = useQueryOperatorTranslations()
    const resolveLocalizedString = useLocalizedStringResolver()
    const { t } = useParticipantGroupOptionGroupTranslations()

    const common = {
        name: participantFilterGroupKey,
        label: t('label'),
    } as const

    return {
        label: t('label'),
        options: [
            {
                ...common,
                valueEditorType: 'select',
                values: groups.map(group => ({ name: group.key, label: resolveLocalizedString(group.name) })),
                operators: (['contains', 'doesNotContain'] as const).map(op => ({
                    name: op,
                    label: queryT(op),
                })),
            },
        ],
    }
}

const selectOperators = ['in', 'notIn'] as const
const textOperators = ['=', '!=', 'contains', 'doesNotContain', 'beginsWith', 'endsWith'] as const
const floatOperators = ['=', '!=', '>', '>=', '<', '<='] as const
const dateOperators = ['before', 'after', 'on', 'notOn'] as const

const useQueryOperators = (): Operators => {
    const { t } = useQueryOperatorTranslations()

    return {
        select: selectOperators.map(op => ({ name: op, label: t(op) })),
        text: textOperators.map(op => ({ name: op, label: t(op) })),
        float: floatOperators.map(op => ({ name: op, label: t(op) })),
        date: dateOperators.map(op => ({ name: op, label: t(op) })),
    }
}

const useQueryOperatorTranslations = createUseTranslation({
    FR: {
        in: 'est parmi',
        notIn: `n'est pas parmi`,
        contains: 'contient',
        doesNotContain: 'ne contient pas',
        beginsWith: 'commence par',
        endsWith: 'se termine par',
        '=': 'est égal à',
        '!=': `n'est pas égal à`,
        '>': 'est strictement supérieur à',
        '>=': 'est supérieur ou égal à',
        '<': 'est strictement inférieur à',
        '<=': 'est inférieur ou égal à',
        before: 'est avant',
        after: 'est après',
        on: 'est exactement',
        notOn: `est autre que`,
    },
    EN: {
        in: 'is in',
        notIn: 'is not in',
        contains: 'contains',
        doesNotContain: 'does not contain',
        beginsWith: 'begins with',
        endsWith: 'ends with',
        '=': 'equals',
        '!=': 'does not equal',
        '>': 'is greater than',
        '>=': 'is greater than or equal',
        '<': 'is less than',
        '<=': 'is less than or equal',
        before: 'is before',
        after: 'is after',
        on: 'is on',
        notOn: 'is not on',
    },
})

type Operators = {
    select: Option[]
    text: Option[]
    float: Option[]
    date: Option[]
}

const useAddressTranslations = createUseTranslation({
    FR: {
        address: 'Adresse',
        city: 'Ville',
        postCode: 'Code Postal',
        country: 'Pays',
    },
    EN: {
        address: 'Address',
        city: 'City',
        postCode: 'Post Code',
        country: 'Country',
    },
})

const useAddressLabels = (): AddressLabels => {
    const { t } = useAddressTranslations()

    return {
        address: t('address'),
        city: t('city'),
        postCode: t('postCode'),
        country: t('country'),
    }
}

type AddressLabels = {
    address: string
    city: string
    postCode: string
    country: string
}

const queryFieldsForField = (
    field: FilterField,
    locale: KnownLocale,
    operators: Operators,
    addressLabels: AddressLabels
): QueryField[] => {
    const parameters = field.parameters

    const common = {
        name: field.key,
        label: resolveLocalizedString(field.name, locale),
    } as const

    switch (parameters.__typename) {
        case 'LookupFieldParameters':
            return [
                {
                    ...common,
                    valueEditorType: 'multiselect',
                    values: queryFieldValuesForDictionary(parameters.dictionary, locale),
                    operators: operators.select,
                },
            ]

        case 'FloatFieldParameters':
            return [
                {
                    ...common,
                    operators: operators.float,
                    inputType: 'number',
                },
            ]

        case 'DateFieldParameters':
            return [
                {
                    ...common,
                    operators: operators.date,
                    inputType: 'date',
                },
            ]

        case 'MapFieldParameters':
            switch (parameters.subType) {
                case 'ADDRESS':
                    return [
                        {
                            name: addressNames.address(field.key),
                            label: `${resolveLocalizedString(field.name, locale)} - ${addressLabels.address}`,
                            valueEditorType: 'text',
                            operators: operators.text,
                        },
                        {
                            name: addressNames.city(field.key),
                            label: `${resolveLocalizedString(field.name, locale)} - ${addressLabels.city}`,
                            valueEditorType: 'text',
                            operators: operators.text,
                        },
                        {
                            name: addressNames.postCode(field.key),
                            label: `${resolveLocalizedString(field.name, locale)} - ${addressLabels.postCode}`,
                            valueEditorType: 'text',
                            operators: operators.text,
                        },
                        {
                            name: addressNames.country(field.key),
                            label: `${resolveLocalizedString(field.name, locale)} - ${addressLabels.country}`,
                            valueEditorType: 'multiselect',
                            values: queryFieldValuesForDictionary('COUNTRY', locale),
                            operators: operators.text,
                        },
                    ]
                default:
                    throw lang.NotExhaustedError(parameters.subType)
            }

        default:
            return [
                {
                    ...common,
                    valueEditorType: 'text',
                    operators: operators.text,
                },
            ]
    }
}

const addressNames = {
    address: (key: string) => `${key}_address`,
    city: (key: string) => `${key}_city`,
    postCode: (key: string) => `${key}_postCode`,
    country: (key: string) => `${key}_country`,
}

const queryFieldValuesForDictionary = (dictionary: graphql.LookupValueDictionary, locale: KnownLocale): Option[] => {
    let lookupValues: { key: string; value: string }[]

    switch (dictionary) {
        case 'COUNTRY':
            lookupValues = countryLookup.entriesForLocale(locale)
            break
        case 'MARITAL_STATUS':
            lookupValues = maritalStatusLookup.entriesForLocale(locale)
            break
        case 'NATIONALITY':
            lookupValues = nationalityLookup.entriesForLocale(locale)
            break
        case 'TITLE':
            lookupValues = titlesLookup.entriesForLocale(locale)
            break
    }

    return lookupValues.map(({ key, value }) => ({ name: key, label: value }))
}

type ParticipantFilterProps = {
    queryFields: OptionGroup<QueryField>[]
    onChange?: (query: RuleGroupType | undefined) => void
}

export const ParticipantFilter: FC<ParticipantFilterProps> = ({ queryFields, onChange }) => {
    const [query, setQuery] = useState<RuleGroupType>()
    const firstRun = useRef(true)

    const translations = useQueryBuilderLabels()
    const combinators = useCombinators()

    const onQueryChange = useCallback((query: RuleGroupType) => {
        setQuery(query)
    }, [])

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false
            return
        }

        onChange?.(query)
    }, [onChange, query])

    return (
        <Form.Item hasFeedback={false}>
            <QueryBuilderAntD>
                <QueryBuilder
                    fields={queryFields}
                    combinators={combinators}
                    onQueryChange={onQueryChange}
                    translations={translations}
                    controlElements={controls}
                />
            </QueryBuilderAntD>
        </Form.Item>
    )
}

const useRemoveStyles = createUseStyles({
    delete: {
        color: colors.grey6,
    },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Remove: FC<any> = ({ label: _label, disabledTranslation: _disabledTranslation, className, ...props }) => {
    const styles = useRemoveStyles()

    return (
        <AntDActionElement
            {...props}
            className={[styles.delete, className as string].join(' ')}
            type="link"
            icon={icons.Delete}
        />
    )
}

const controls: Partial<QueryControls> = {
    removeRuleAction: Remove,
    removeGroupAction: Remove,
}

const useQueryBuilderLabelTranslations = createUseTranslation({
    FR: {
        remove: 'Supprimer',
        fields: 'Champs',
        operators: 'Comparaison',
        value: 'Valeur',
        addRule: 'Ajouter condition',
        addGroup: 'Ajouter groupe',
    },
    EN: {
        remove: 'Remove',
        fields: 'Fields',
        operators: 'Operator',
        value: 'Value',
        addRule: 'Add Rule',
        addGroup: 'Add Group',
    },
})

const useQueryBuilderLabels = (): Partial<QueryTranslations> => {
    const { t } = useQueryBuilderLabelTranslations()

    return {
        fields: {
            title: t('fields'),
        },
        operators: {
            title: t('operators'),
        },
        value: {
            title: t('value'),
        },
        removeRule: {
            title: t('remove'),
        },
        removeGroup: {
            title: t('remove'),
        },
        addRule: {
            title: t('addRule'),
            label: t('addRule'),
        },
        addGroup: {
            title: t('addGroup'),
            label: t('addGroup'),
        },
        combinators: {
            title: '',
        },
    }
}

const useCombinatorTranslations = createUseTranslation({
    FR: {
        and: 'Toutes les conditions doivent être remplies',
        or: 'Au moins une condition doit être remplie',
    },
    EN: {
        and: 'All conditions must be met',
        or: 'At least one condition must be met',
    },
})

const combinators = ['and', 'or'] as const

const useCombinators = (): Option[] => {
    const { t } = useCombinatorTranslations()

    return combinators.map(combinator => ({ name: combinator, label: t(combinator) }))
}

export const useJSONLogicCompatibleParticipantValues = <P extends Participant & { groups: { key: string }[] }>(
    participants: P[]
): { participant: P; data: Record<string, unknown> }[] => {
    return useMemo(
        () =>
            participants.map(participant => {
                const fieldValues = participant.values.reduce(
                    (data, value): Record<string, unknown> => {
                        const key = value.key

                        switch (value.__typename) {
                            case 'TextValue':
                                return {
                                    ...data,
                                    [key]: normalizeString(value.textValue).toLowerCase(),
                                }

                            case 'LookupValue':
                                return {
                                    ...data,
                                    [key]: value.lookupValue.key.toLowerCase(),
                                }

                            case 'FloatValue':
                                return {
                                    ...data,
                                    [key]: value.floatValue,
                                }

                            case 'DateValue':
                                return {
                                    ...data,
                                    [key]: value.dateValue.toISODate(),
                                }

                            case 'BooleanValue':
                                return {
                                    ...data,
                                    [key]: value.booleanValue,
                                }

                            case 'MapValue':
                                return {
                                    ...data,
                                    ...value.mapValue.reduce(
                                        (kvs, kv) => ({
                                            ...kvs,
                                            [`${value.key}_${kv.key}`]: normalizeString(kv.value).toLowerCase(),
                                        }),
                                        {} as Record<string, string>
                                    ),
                                }

                            default:
                                throw lang.NotExhaustedError(value)
                        }
                    },
                    {} as Record<string, unknown>
                )

                const groupValues = {
                    [participantFilterGroupKey]: participant.groups.map(group => group.key.toLowerCase()),
                }

                return {
                    participant,
                    data: {
                        ...fieldValues,
                        ...groupValues,
                    },
                }
            }),
        [participants]
    )
}
