import { z } from 'zod'

import type { FieldParameters } from '@publica/api-graphql'
import { type KnownLocale, defaultLocale, localeSchema } from '@publica/locales'
import { type DateFormat, currencySchema, dateFormatSchema } from '@publica/lookups'
import { numberSchema } from '@publica/schemas'
import { assert } from '@publica/utils'

import { fieldInstanceStateSchema, hoistAttributes } from './rawFieldInstance'

const valueFieldInstanceKindSchema = z.literal('VALUE')

export const valueFieldInstanceParametersSchema = z.union([
    z.object({
        type: z.literal('text'),
    }),
    z.object({
        type: z.literal('date'),
        locale: localeSchema,
        dateFormat: dateFormatSchema,
    }),
    z.object({
        type: z.literal('lookup'),
        locale: localeSchema,
    }),
    z.object({
        type: z.literal('float'),
        locale: localeSchema,
        precision: numberSchema.default(2).refine(v => {
            return v >= 0 && v <= 2
        }, 'Invalid precision range'),
        numberFormat: z.enum(['NUMBER', 'WORDS']),
        currency: currencySchema.optional(),
    }),
    z.object({
        type: z.literal('map'),
        locale: localeSchema,
        subType: z.enum(['ADDRESS']),
        key: z.string().optional(),
    }),
])

export const unhoistedValueFieldInstanceSchema = z
    .object({
        key: z.string(),
        params: z.intersection(
            z.object({
                kind: valueFieldInstanceKindSchema,
                state: fieldInstanceStateSchema,
            }),
            valueFieldInstanceParametersSchema
        ),
    })
    .strict()
    .transform(hoistAttributes)

export const hoistedValueFieldInstanceSchema = z
    .object({
        key: z.string(),
        kind: valueFieldInstanceKindSchema,
        state: fieldInstanceStateSchema,
        params: valueFieldInstanceParametersSchema,
    })
    .strict()

export const valueFieldInstanceSchema = z.union([unhoistedValueFieldInstanceSchema, hoistedValueFieldInstanceSchema])

export type ValueFieldInstance = z.infer<typeof unhoistedValueFieldInstanceSchema>

export type ValueFieldInstanceParameters = z.infer<typeof valueFieldInstanceParametersSchema>

export type ValueFieldInstanceParametersForType<
    T extends ValueFieldInstanceParameters['type'],
    V extends ValueFieldInstanceParameters = ValueFieldInstanceParameters,
> = V extends { type: T } ? V : never

type ValueFieldInstanceParametersWithKey<P extends ValueFieldInstanceParameters, K extends string, V> = P extends {
    [T in K]: V
}
    ? P
    : never

type ValueFieldInstanceWithParameter<K extends string, V> = Omit<ValueFieldInstance, 'params'> & {
    params: ValueFieldInstanceParametersWithKey<ValueFieldInstanceParameters, K, V>
}

export type LocalizableValueFieldInstance = ValueFieldInstanceWithParameter<'locale', KnownLocale>
export type DateFormattableValueFieldInstance = ValueFieldInstanceWithParameter<'dateFormat', DateFormat>
export type PrecisionValueFieldInstance = ValueFieldInstanceWithParameter<'precision', number>
export type MapValueFieldInstance = Omit<ValueFieldInstance, 'params'> & {
    params: ValueFieldInstanceParametersForType<'map'>
}

/* istanbul ignore next */
export const valueFieldInstanceIsLocalizable = (
    valueFieldInstance: ValueFieldInstance
): valueFieldInstance is LocalizableValueFieldInstance => {
    const params = valueFieldInstance.params

    switch (params.type) {
        case 'date':
        case 'lookup':
        case 'float':
            return true
        case 'map':
            return (params as ValueFieldInstanceParametersForType<'map'>).subType === 'ADDRESS'
        default:
            return false
    }
}

/* istanbul ignore next */
export const valueFieldInstanceIsDateFormattable = (
    valueFieldInstance: ValueFieldInstance
): valueFieldInstance is DateFormattableValueFieldInstance => valueFieldInstance.params.type === 'date'

/* istanbul ignore next */
export const valueFieldInstanceHasPrecision = (
    valueFieldInstance: ValueFieldInstance
): valueFieldInstance is PrecisionValueFieldInstance => valueFieldInstance.params.type === 'float'

/* istanbul ignore next */
export const valueFieldInstanceIsMap = (
    valueFieldInstance: ValueFieldInstance
): valueFieldInstance is MapValueFieldInstance => valueFieldInstance.params.type === 'map'

/* istanbul ignore next */
export const createValueFieldInstance = (key: string, params: ValueFieldInstanceParameters): ValueFieldInstance => {
    return {
        key,
        kind: 'VALUE',
        state: 'VALID',
        params,
    }
}

/* istanbul ignore next */
export const createValueFieldInstanceWithDefaultParameters = (
    key: string,
    type: ValueFieldInstanceParameters['type'],
    locale: KnownLocale
): ValueFieldInstance => {
    const params = getDefaultValueFieldInstanceParametersForType(type, locale)

    return {
        key,
        kind: 'VALUE',
        state: 'VALID',
        params,
    }
}

/* istanbul ignore next */
export const createValueFieldInstanceWithDefaultParametersForGraphQLType = (
    key: string,
    field: {
        parameters: FieldParameters
    },
    locale: KnownLocale
): ValueFieldInstance => {
    const params = getDefaultValueFieldInstanceParametersForGraphQLType(field, locale)

    return {
        key,
        kind: 'VALUE',
        state: 'VALID',
        params,
    }
}

/* istanbul ignore next */
export const getDefaultValueFieldInstanceParametersForType = <T extends ValueFieldInstanceParameters['type']>(
    type: T,
    locale = defaultLocale
): ValueFieldInstanceParametersForType<T> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    switch (type as ValueFieldInstanceParameters['type']) {
        case 'date':
            return {
                type: 'date',
                locale,
                dateFormat: 'CONDENSED',
            } as ValueFieldInstanceParametersForType<T>
        case 'lookup':
            return {
                type: 'lookup',
                locale,
            } as ValueFieldInstanceParametersForType<T>
        case 'text':
            return {
                type: 'text',
            } as ValueFieldInstanceParametersForType<T>
        case 'float':
            return {
                type: 'float',
                precision: 2,
                locale,
                numberFormat: 'NUMBER',
            } as ValueFieldInstanceParametersForType<T>
        case 'map': {
            return {
                type: 'map',
                locale,
                subType: 'ADDRESS',
            } as ValueFieldInstanceParametersForType<T>
        }
    }
}

/* istanbul ignore next */
export const getDefaultValueFieldInstanceParametersForGraphQLType = (
    field: {
        parameters: FieldParameters
    },
    locale = defaultLocale
): ValueFieldInstanceParameters => {
    const parameters = field.parameters

    assert.defined(parameters.__typename)

    switch (parameters.__typename) {
        case 'DateFieldParameters':
            return getDefaultValueFieldInstanceParametersForType('date', locale)
        case 'LookupFieldParameters':
            return getDefaultValueFieldInstanceParametersForType('lookup', locale)
        case 'MapFieldParameters':
            return getDefaultValueFieldInstanceParametersForType('map', locale)
        case 'TextFieldParameters':
            return getDefaultValueFieldInstanceParametersForType('text', locale)
        case 'FloatFieldParameters':
            // eslint-disable-next-line no-case-declarations
            const defaults = getDefaultValueFieldInstanceParametersForType('float', locale)

            return {
                ...defaults,
                currency: parameters.currency ?? defaults.currency,
                precision: parameters.precision,
            }
    }
}
