import { LookupList, countryLookup, maritalStatusLookup, nationalityLookup, titlesLookup } from '@publica/lookups'
import { FC } from '@publica/ui-common-utils'

import { LookupSelect } from '../../../'
import { Field, LookupFieldParameters } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

type LookupValueFormItemProps = {
    field: Field<LookupFieldParameters>
}

export const LookupValueFormItem: FC<LookupValueFormItemProps> = ({ field }) => {
    let lookup: LookupList<string>
    const lookupValueRules = useCommonRules()

    switch (field.parameters.dictionary) {
        case 'COUNTRY':
            lookup = countryLookup
            break
        case 'MARITAL_STATUS':
            lookup = maritalStatusLookup
            break
        case 'NATIONALITY':
            lookup = nationalityLookup
            break
        case 'TITLE':
            lookup = titlesLookup
            break
    }

    return (
        <FormItem field={field} rules={lookupValueRules}>
            <LookupSelect lookup={lookup} disabled={field.lockedDueToSelfRepresentation} />
        </FormItem>
    )
}
