import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { assert, buildIdMap, buildMap, buildMapWithFn } from '@publica/utils'

import {
    GetOperationParticipantsByQueryDocument,
    LegalEntityCompanyCreateInput,
    LegalEntityIndividualCreateInput,
    useCreateAccountsMutation,
    useCreateLegalEntitiesMutation,
    useCreateParticipantsMutation,
    useGetAccountsAndLegalEntitiesForBulkAddLazyQuery,
} from '../../../../../../data'
import {
    AddParticipantAccountStep,
    AddParticipantActionsRequired,
    AddParticipantLegalEntityStep,
    AddParticipantParticipantStep,
    AddParticipantState,
    AddParticipantStateWithProgress,
    UploadedParticipant,
    UploadedParticipantOrError,
} from '../types'
import { ImportParticipantsPlanSummary } from './ImportParticipantsPlanSummary'

type ImportParticipantsPlanProps = {
    operationId: string
    participantsPendingImport: UploadedParticipantOrError[]
    onCancel: () => void
    onComplete: () => void
}

export const ImportParticipantsPlan: FC<ImportParticipantsPlanProps> = ({
    participantsPendingImport,
    operationId,
    onCancel,
    onComplete,
}) => {
    const { plan } = useImportParticipantPlan(participantsPendingImport, operationId)
    const { state, doImport } = useImporter(plan ?? [], operationId)

    const onConfirm = useAsyncCallback(async () => {
        void doImport().then(() => {
            onComplete()
        })
    }, [doImport, onComplete])

    return (
        <ImportParticipantsPlanSummary
            state={state}
            loading={plan === undefined}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    )
}

const useImportParticipantPlan = (participantsPendingImport: UploadedParticipantOrError[], operationId: string) => {
    const [getAccountsAndLegalEntitiesForBulkAddQuery] = useGetAccountsAndLegalEntitiesForBulkAddLazyQuery()
    const [plan, setPlan] = useState<AddParticipantState[] | undefined>(undefined)

    useEffect(() => {
        void (async () => {
            if (participantsPendingImport.length === 0) {
                setPlan(undefined)
                return
            }

            const nonErroredParticipants: UploadedParticipant[] = []

            for (const uploadedParticipant of participantsPendingImport) {
                if (uploadedParticipant.type !== 'ERROR') {
                    nonErroredParticipants.push(uploadedParticipant)
                }
            }

            const { accounts, legalEntities } = await getAccountsAndLegalEntitiesForBulkAddQuery({
                variables: {
                    ...nonErroredParticipants.reduce(
                        (vars, uploadedParticipant) => {
                            vars.emails.push(uploadedParticipant.account.email)
                            vars.identifiers.push(uploadedParticipant.legalEntity.identifier)
                            return vars
                        },
                        {
                            emails: [],
                            identifiers: [],
                        } as { emails: string[]; identifiers: string[] }
                    ),
                    operationId,
                },
                fetchPolicy: 'no-cache',
            }).then(({ data }) => {
                assert.defined(data)
                const { accountsByEmail, legalEntitiesByIdentifiers } = data

                return {
                    accounts: accountsByEmail,
                    legalEntities: legalEntitiesByIdentifiers,
                }
            })

            const accountsByEmail = buildMap('email', accounts)
            const legalEntitiesByIdentifiers = buildMap('identifier', legalEntities)
            const participantsByLegalEntityId = legalEntities.reduce(
                (participantsByLegalEntityId, legalEntity) => {
                    if (isNil(legalEntity.participant)) {
                        return participantsByLegalEntityId
                    }

                    return {
                        ...participantsByLegalEntityId,
                        [legalEntity.id]: legalEntity.participant,
                    }
                },
                {} as Record<string, { id: string }>
            )

            const state = participantsPendingImport.map((uploadedParticipant): AddParticipantState => {
                const rowAndId = {
                    row: uploadedParticipant.row,
                    id: uploadedParticipant.row.toString(),
                } as const

                if (uploadedParticipant.type === 'ERROR') {
                    return {
                        status: 'ERROR',
                        errorType: uploadedParticipant.errorType,
                        ...rowAndId,
                    }
                }

                const account = accountsByEmail[uploadedParticipant.account.email]

                let accountStep: AddParticipantAccountStep

                if (account === undefined) {
                    accountStep = {
                        state: 'ACCOUNT_DOES_NOT_EXIST',
                    }
                } else if (account.type !== 'PARTICIPANT') {
                    return {
                        status: 'ERROR',
                        errorType: 'ACCOUNT_IS_ADMIN',
                        ...rowAndId,
                    }
                } else {
                    accountStep = {
                        state: 'READY',
                        account,
                    }
                }

                const legalEntity = legalEntitiesByIdentifiers[uploadedParticipant.legalEntity.identifier]

                let legalEntityStep: AddParticipantLegalEntityStep

                if (legalEntity === undefined) {
                    legalEntityStep = {
                        state: 'LEGAL_ENTITY_DOES_NOT_EXIST',
                    }
                } else if (isNil(legalEntity.representative)) {
                    legalEntityStep = {
                        state: 'LEGAL_ENTITY_EXISTS_AND_NEEDS_LINKING',
                        legalEntity,
                    }
                } else if (legalEntity.representative.account.id !== account?.id) {
                    return {
                        status: 'ERROR',
                        errorType: 'ACCOUNT_LEGAL_ENTITY_MISMATCH',
                        ...rowAndId,
                    }
                } else {
                    legalEntityStep = {
                        state: 'READY',
                        legalEntity,
                    }
                }

                let participantStep: AddParticipantParticipantStep

                if (legalEntity === undefined) {
                    participantStep = {
                        state: 'PARTICIPANT_DOES_NOT_EXIST',
                    }
                } else {
                    const participant = participantsByLegalEntityId[legalEntity.id]

                    if (participant === undefined) {
                        participantStep = {
                            state: 'PARTICIPANT_DOES_NOT_EXIST',
                        }
                    } else {
                        participantStep = {
                            state: 'READY',
                            participant,
                        }
                    }
                }

                if (
                    accountStep.state === 'READY' &&
                    legalEntityStep.state === 'READY' &&
                    participantStep.state === 'READY'
                ) {
                    return {
                        uploadedParticipant,
                        status: 'NO_ACTIONS_REQUIRED',
                        ...rowAndId,
                        steps: {
                            account: accountStep,
                            legalEntity: legalEntityStep,
                            participant: participantStep,
                        },
                    }
                }

                return {
                    uploadedParticipant,
                    status: 'ACTIONS_REQUIRED',
                    ...rowAndId,
                    steps: {
                        account: accountStep,
                        legalEntity: legalEntityStep,
                        participant: participantStep,
                    },
                }
            })

            setPlan(state)
        })()
    }, [getAccountsAndLegalEntitiesForBulkAddQuery, operationId, participantsPendingImport])

    return { plan }
}

const useImporter = (participants: AddParticipantState[], operationId: string) => {
    const [createParticipants] = useCreateParticipantsMutation()
    const [createAccounts] = useCreateAccountsMutation()
    const [createLegalEntities] = useCreateLegalEntitiesMutation()
    const [participantsById, setParticipantsById] = useState<Record<string, AddParticipantStateWithProgress>>({})

    const state = useMemo(() => Object.values(participantsById), [participantsById])

    useEffect(() => {
        setParticipantsById(
            participants.reduce(
                (participants, participant) => ({
                    ...participants,
                    [participant.id]: {
                        ...participant,
                        progress:
                            participant.status === 'ERROR'
                                ? 'WONT_START'
                                : participant.status === 'NO_ACTIONS_REQUIRED'
                                  ? 'COMPLETED'
                                  : 'PENDING',
                    },
                }),
                {} as Record<string, AddParticipantStateWithProgress>
            )
        )
    }, [participants])

    const doImport = useCallback(async () => {
        if (participants.length === 0) {
            return
        }

        let participantsNeedingActions = participants.filter(participant => participant.status === 'ACTIONS_REQUIRED')

        const updateParticipantsState = (
            participantsToUpdate: AddParticipantActionsRequired[],
            progress: AddParticipantStateWithProgress['progress']
        ) => {
            const participantsById = buildIdMap(participants)

            for (const participant of participantsToUpdate) {
                participantsById[participant.id] = participant
            }

            const updatedParticipants = Object.values(participantsById)

            setParticipantsById(participants =>
                updatedParticipants.reduce(
                    (participantsById, participant) => ({
                        ...participantsById,
                        [participant.id]: {
                            ...participant,
                            progress,
                        },
                    }),
                    participants
                )
            )

            return updatedParticipants.filter(participant => participant.status === 'ACTIONS_REQUIRED')
        }

        const participantsNeedingAccounts = participantsNeedingActions.filter(
            participant => participant.steps.account.state === 'ACCOUNT_DOES_NOT_EXIST'
        )

        participantsNeedingActions = updateParticipantsState(
            participantsNeedingAccounts.map(participant => ({
                ...participant,
                steps: {
                    ...participant.steps,
                    account: {
                        ...participant.steps.account,
                        state: 'CREATING_ACCOUNT',
                    },
                },
            })),
            'IN_PROGRESS'
        )

        // This will squash accounts with the same email
        const accountsToCreate = Object.values(
            buildMapWithFn(p => p.uploadedParticipant.account.email, participantsNeedingAccounts)
        ).map(participant => participant.uploadedParticipant.account)

        await createAccounts({
            variables: {
                accounts: accountsToCreate,
            },
        }).then(({ data }) => {
            assert.defined(data)

            const accountsByEmail = buildMapWithFn(account => account.email, data.createAccounts)

            participantsNeedingActions = updateParticipantsState(
                participantsNeedingAccounts.map(participant => ({
                    ...participant,
                    steps: {
                        ...participant.steps,
                        account: {
                            state: 'READY',
                            account: accountsByEmail[participant.uploadedParticipant.account.email]!,
                        },
                    },
                })),
                'IN_PROGRESS'
            )
        })

        const participantsNeedingLegalEntities = participantsNeedingActions.filter(
            participant => participant.steps.legalEntity.state === 'LEGAL_ENTITY_DOES_NOT_EXIST'
        )

        participantsNeedingActions = updateParticipantsState(
            participantsNeedingLegalEntities.map(participant => ({
                ...participant,
                steps: {
                    ...participant.steps,
                    legalEntity: {
                        state: 'CREATING_LEGAL_ENTITY',
                    },
                },
            })),
            'IN_PROGRESS'
        )

        await createLegalEntities({
            variables: participantsNeedingLegalEntities.reduce(
                (vars, participant) => {
                    const account = participant.steps.account

                    if (account.state !== 'READY') {
                        throw new Error(`Attempting to create a legal entity before account is ready`)
                    }

                    const { legalEntity } = participant.uploadedParticipant

                    if (legalEntity.type === 'INDIVIDUAL') {
                        vars.individuals.push({
                            representative: {
                                accountId: account.account.id,
                                type: legalEntity.representationType,
                            },
                            email: legalEntity.email,
                            firstName: legalEntity.firstName,
                            lastName: legalEntity.lastName,
                            title: legalEntity.title,
                        })
                    } else {
                        vars.companies.push({
                            representative: {
                                accountId: account.account.id,
                                type: legalEntity.representationType,
                            },
                            registrationNumber: legalEntity.registrationNumber,
                            name: legalEntity.name,
                        })
                    }

                    return vars
                },
                {
                    companies: [],
                    individuals: [],
                } as {
                    companies: LegalEntityCompanyCreateInput[]
                    individuals: LegalEntityIndividualCreateInput[]
                }
            ),
        }).then(({ data }) => {
            assert.defined(data)

            const legalEntitiesByIdentifier = buildMapWithFn(
                legalEntity => legalEntity.identifier,
                [...data.createLegalEntityCompanies, ...data.createLegalEntityIndividuals]
            )

            participantsNeedingActions = updateParticipantsState(
                participantsNeedingLegalEntities.map(participant => ({
                    ...participant,
                    steps: {
                        ...participant.steps,
                        legalEntity: {
                            state: 'READY',
                            legalEntity:
                                legalEntitiesByIdentifier[participant.uploadedParticipant.legalEntity.identifier]!,
                        },
                    },
                })),
                'IN_PROGRESS'
            )
        })

        const participantsNeedingLinkingWithExistingLegalEntities = participantsNeedingActions.filter(
            participant => participant.steps.legalEntity.state === 'LEGAL_ENTITY_EXISTS_AND_NEEDS_LINKING'
        )

        if (participantsNeedingLinkingWithExistingLegalEntities.length > 0) {
            throw new Error('Not yet implemented')
        }

        const participantsNeedingParticipants = participantsNeedingActions.filter(
            participant => participant.steps.participant.state === 'PARTICIPANT_DOES_NOT_EXIST'
        )

        participantsNeedingActions = updateParticipantsState(
            participantsNeedingParticipants.map(participant => ({
                ...participant,
                steps: {
                    ...participant.steps,
                    participant: {
                        state: 'CREATING_PARTICIPANT',
                    },
                },
            })),
            'IN_PROGRESS'
        )

        await createParticipants({
            variables: {
                operationId,
                participants: sortBy(participantsNeedingParticipants, p => p.row).map(participant => {
                    const legalEntity = participant.steps.legalEntity

                    if (legalEntity.state !== 'READY') {
                        throw new Error('Attempting to create participant before legal entity is ready')
                    }

                    return {
                        legalEntityId: legalEntity.legalEntity.id,
                    }
                }),
            },
            refetchQueries: [GetOperationParticipantsByQueryDocument],
        }).then(({ data }) => {
            assert.defined(data)

            const createdParticipantsByLegalEntityId = buildMapWithFn(
                participant => participant.legalEntity.id,
                data.createParticipants
            )

            const legalEntityId = (legalEntity: AddParticipantLegalEntityStep) => {
                if (legalEntity.state !== 'READY') {
                    throw new Error(`Attempting to create participant before legal entity is ready`)
                }

                return legalEntity.legalEntity.id
            }

            participantsNeedingActions = updateParticipantsState(
                participantsNeedingParticipants.map(participant => ({
                    ...participant,
                    steps: {
                        ...participant.steps,
                        participant: {
                            state: 'READY',
                            participant:
                                createdParticipantsByLegalEntityId[legalEntityId(participant.steps.legalEntity)]!,
                        },
                    },
                })),
                'COMPLETED'
            )
        })

        updateParticipantsState(participantsNeedingActions, 'COMPLETED')
    }, [createAccounts, createLegalEntities, createParticipants, operationId, participants])

    return { doImport, state }
}
