/* istanbul ignore file */

type Event<T extends string = string> = { type: T }

type EventsOfType<E extends Event, T extends string> = E extends { type: T } ? E : never

export type EventListener<E extends Event> = (event: E) => Promise<void>

export class EventEmitter<E extends Event> {
    public listeners: Partial<{ [K in E['type']]: Set<EventListener<EventsOfType<E, K>>> }>

    constructor() {
        this.listeners = {}
    }

    on<ET extends E['type']>(type: ET, listener: EventListener<EventsOfType<E, ET>>) {
        const listeners = (this.listeners[type] ??= new Set())
        listeners.add(listener)
    }

    off<ET extends E['type']>(type: ET, listener: EventListener<EventsOfType<E, ET>>) {
        const listeners = this.listeners[type]
        if (listeners === undefined) {
            return
        }

        listeners.delete(listener)
    }

    async emit(event: E) {
        for (const li of this.listeners[event.type as E['type']] ?? []) {
            await li(event as EventsOfType<E, E['type']>)
        }
    }
}
