import A1 from '@flighter/a1-notation'
import type ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'

import { fullName } from '@publica/common'
import { KnownLocale, localeSchema } from '@publica/locales'
import {
    languagesLookup,
    legalEntityRepresentationTypeLookup,
    legalEntityTypeLookup,
    titlesLookup,
} from '@publica/lookups'
import { emailSchema } from '@publica/schemas'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { logger } from '@publica/ui-common-logger'
import { useAsyncCallback } from '@publica/ui-common-utils'

import { UploadedParticipant, UploadedParticipantOrError } from './types'

const headerOffset = 2

const rawSheetSchema = z.array(z.record(z.union([z.string().transform(val => val.trim()), z.number()])))

const accountSchema = z.object({
    accountTitle: z.enum(titlesLookup.keys()),
    accountFirstName: z.string().min(1),
    accountLastName: z.string().min(1),
    accountEmail: emailSchema,
    accountLocale: localeSchema,
})

const zeroLengthStringOrUndefined = z.union([z.string().refine(val => val.trim().length === 0), z.undefined()])

const selfRepresentedIndividualSchema = z
    .object({
        participantType: z.literal('INDIVIDUAL'),
        individualTitle: zeroLengthStringOrUndefined,
        individualFirstName: zeroLengthStringOrUndefined,
        individualLastName: zeroLengthStringOrUndefined,
        individualEmail: zeroLengthStringOrUndefined,
        representationType: z.literal('SELF_REPRESENTATION').default('SELF_REPRESENTATION'),
    })
    .transform(({ participantType, representationType }) => ({ participantType, representationType }))

const individualSchema = z.object({
    participantType: z.literal('INDIVIDUAL'),
    individualTitle: z.enum(titlesLookup.keys()),
    individualFirstName: z.string().min(1),
    individualLastName: z.string().min(1),
    individualEmail: z.union([
        emailSchema,
        z
            .string()
            .optional()
            .refine(val => (val ?? '').trim().length === 0)
            .transform(() => generateEmail()),
    ]),
    representationType: z.enum(['SELF_REPRESENTATION', 'DELEGATION']),
})

const generateEmail = () => `${uuid()}@publica.nexdo.co.internal`

const companySchema = z.object({
    participantType: z.literal('COMPANY'),
    companyName: z.string().min(1),
    companyRegistrationNumber: z
        .string()
        .min(1)
        .transform(id => id.toUpperCase().replaceAll(' ', '')),
    representationType: z.literal('DELEGATION'),
})

const participantSchema = z.intersection(
    accountSchema,
    // The order is important here, individualSchema must come before selfRepresentedIndividualSchema
    z.union([individualSchema, selfRepresentedIndividualSchema, companySchema])
)

const worksheetToJson = async (worksheet: ExcelJS.Worksheet): Promise<unknown[]> => {
    const ExcelJS = await import('exceljs')
    const rows: unknown[] = []

    worksheet.eachRow((row, rowNumber) => {
        // Row numbers are 1-index based
        // We skip the first row, as it's the column groups
        if (rowNumber > headerOffset) {
            const rowObj: Record<string, string | number | undefined> = {}

            row.eachCell((cell, cellNumber) => {
                // Cells are 1-index, so we subtract
                const key = columns[cellNumber - 1]?.[1]

                if (key !== undefined) {
                    const model = cell.model
                    let val: string | undefined

                    switch (model.type) {
                        // Emails sometimes get stored as hyperlinks
                        case ExcelJS.ValueType.Hyperlink:
                            val = model.text
                            break
                        default:
                            val = model.value?.toString()
                    }

                    rowObj[key] = val
                }
            })

            rows.push(rowObj)
        }
    })

    return rows
}

export const getUploadedParticipants = async (file: File): Promise<UploadedParticipantOrError[]> => {
    const ExcelJS = await import('exceljs')
    const buffer = await file.arrayBuffer()

    const workbook = new ExcelJS.Workbook()
    await workbook.xlsx.load(buffer)

    const sheet = workbook.worksheets[0]

    if (sheet === undefined) {
        throw new Error('No sheets found in workbook')
    }

    const data = await worksheetToJson(sheet)
    const rows = rawSheetSchema.parse(data)

    const participants = getParticipantsFromRows(rows)

    return participants
}

const getParticipantsFromRows = (rows: Record<string, string | number>[]) => {
    const seenIndividualEmails = new Set<string>()
    const seenCompanyRegistrationNumbers = new Set<string>()

    return rows.map((row, idx): UploadedParticipantOrError => {
        const parse = participantSchema.safeParse(row)
        // The +1 is to go from 0-index, to 1-index
        const rowNum = idx + headerOffset + 1

        if (!parse.success) {
            logger.warn(`Error parsing row ${idx}: ${parse.error.message}`)

            return { type: 'ERROR', errorType: 'PARSE_ERROR', row: rowNum }
        } else {
            const data = parse.data

            const account: UploadedParticipant['account'] = {
                title: data.accountTitle,
                firstName: data.accountFirstName,
                lastName: data.accountLastName,
                locale: data.accountLocale,
                email: data.accountEmail,
            }

            let legalEntity: UploadedParticipant['legalEntity']

            if (data.participantType === 'COMPANY') {
                legalEntity = {
                    type: 'COMPANY',
                    name: data.companyName,
                    registrationNumber: data.companyRegistrationNumber,
                    identifier: data.companyRegistrationNumber,
                    representationType: data.representationType,
                }

                if (seenCompanyRegistrationNumbers.has(legalEntity.registrationNumber)) {
                    return {
                        type: 'ERROR',
                        errorType: 'DUPLICATE_PARTICIPANT',
                        row: rowNum,
                    }
                }

                seenCompanyRegistrationNumbers.add(legalEntity.registrationNumber)
            } else {
                if ('individualEmail' in data) {
                    legalEntity = {
                        type: 'INDIVIDUAL',
                        title: data.individualTitle,
                        firstName: data.individualFirstName,
                        lastName: data.individualLastName,
                        email: data.individualEmail,
                        identifier: data.individualEmail,
                        name: fullName({ lastName: data.individualLastName, firstName: data.individualFirstName }),
                        representationType: data.representationType,
                    }
                } else {
                    const { locale: _, ...participantData } = account

                    legalEntity = {
                        type: 'INDIVIDUAL',
                        name: fullName(participantData),
                        identifier: participantData.email,
                        representationType: data.representationType,
                        ...participantData,
                    }
                }

                if (seenIndividualEmails.has(legalEntity.identifier)) {
                    return {
                        errorType: 'DUPLICATE_PARTICIPANT',
                        type: 'ERROR',
                        row: rowNum,
                    }
                }

                seenIndividualEmails.add(legalEntity.identifier)
            }

            const participant: UploadedParticipant = {
                type: 'UPLOADED_PARTICIPANT',
                account,
                legalEntity,
                row: rowNum,
            }

            return participant
        }
    })
}

const columns = [
    ['account', 'accountTitle', titlesLookup],
    ['account', 'accountFirstName'],
    ['account', 'accountLastName'],
    ['account', 'accountEmail'],
    ['account', 'accountLocale', languagesLookup],
    ['participantType', 'participantType', legalEntityTypeLookup],
    ['representationType', 'representationType', legalEntityRepresentationTypeLookup],
    ['individual', 'individualTitle', titlesLookup],
    ['individual', 'individualFirstName'],
    ['individual', 'individualLastName'],
    ['individual', 'individualEmail'],
    ['company', 'companyName'],
    ['company', 'companyRegistrationNumber'],
] as const

type ColumnSection = (typeof columns)[number][0]
type Column = (typeof columns)[number][1]

const alternateLocaleKeys: Record<KnownLocale, Record<Column | ColumnSection, string>> = {
    EN: {
        account: 'Account',
        accountFirstName: 'First Name',
        accountTitle: 'Title',
        accountEmail: 'Email',
        accountLocale: 'Language',
        accountLastName: 'Last Name',
        participantType: 'Type',
        individual: 'Individual',
        individualEmail: 'Email',
        individualFirstName: 'First Name',
        individualLastName: 'Last Name',
        individualTitle: 'Title',
        company: 'Company',
        companyName: 'Name',
        companyRegistrationNumber: 'Registration Number',
        representationType: 'Representation',
    },
    FR: {
        account: 'Compte',
        accountFirstName: 'Prénom',
        accountTitle: 'Civilité',
        accountEmail: 'Email',
        accountLastName: 'Nom',
        accountLocale: 'Langue',
        participantType: 'Type',
        individual: 'Personne Physique',
        individualFirstName: 'Prénom',
        individualTitle: 'Civilité',
        individualEmail: 'Email',
        individualLastName: 'Nom',
        company: 'Personne Morale',
        companyName: 'Raison Sociale',
        companyRegistrationNumber: 'SIRET',
        representationType: 'Représentation',
    },
} as const

const useDownloadTemplateTranslation = createUseTranslation(alternateLocaleKeys)

export const useDownloadTemplate = () => {
    const { t } = useDownloadTemplateTranslation()

    return useAsyncCallback(async () => {
        const ExcelJS = await import('exceljs')
        const workbook = new ExcelJS.Workbook()
        const sheet = workbook.addWorksheet('Participants')

        sheet.columns = columns.map(col => ({
            header: [t(col[0]), t(col[1])],
        }))

        let currentSection: string | undefined
        let currentSectionStart = 1

        // This assumes sections are contiguous
        for (let i = 0; i <= columns.length; i++) {
            const col = columns[i]
            const section = col?.[0]

            if (currentSection === undefined) {
                currentSection = section
            } else if (currentSection !== section) {
                sheet.mergeCells(1, currentSectionStart, 1, i)
                currentSectionStart = i + 1
                currentSection = section
            }
        }

        sheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 2, topLeftCell: 'A3', activeCell: 'A3' }]

        for (let i = 3; i <= 1000; i++) {
            for (let j = 0; j < columns.length; j++) {
                const lookup = columns[j]?.[2]
                if (lookup !== undefined) {
                    sheet.getCell(`${A1.toCol(j + 1)}${i}`).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        formulae: [`"${lookup.keys().join(',')}"`],
                    }
                }
            }
        }

        const buffer = await workbook.xlsx.writeBuffer()

        saveAs(new Blob([buffer]), 'Import.xlsx')
    }, [t])
}
