import { createContext } from 'react'

import { FC } from '@publica/ui-common-utils'

import { AuthState } from './state'

export const AuthStateContext = createContext<AuthState | undefined>(undefined)

export type AuthStateBuilder = () => AuthState

type AuthStateProviderProps = {
    builder: AuthStateBuilder
}

export const AuthStateProvider: FC<AuthStateProviderProps> = ({ children, builder }) => {
    const state = builder()
    return <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
}
