import { FileOutlined, FormOutlined, MailOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Space, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { useEffect, useMemo } from 'react'
import { useMatch, useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError } from '@publica/ui-common-utils'
import { OperationHeader, Tab, useTabs } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useGetOperationSuspenseQuery } from '../../../../data'
import { Attachments } from '../../attachments'
import { Documents } from '../../documents'
import { Emails } from '../../emails'
import { Fields } from '../../fields'
import { Participants } from '../../participants'
import { EditOperation } from './EditOperation'
import { ExportOperation } from './ExportOperation'
import { OperationStatusControl } from './OperationStatusControl'

const useRedirectFromRootToParticipants = () => {
    const rootResolved = useResolvedPath('')
    const isRoot = useMatch({ path: rootResolved.pathname, end: true }) !== null

    const navigate = useNavigate()

    useEffect(() => {
        if (isRoot) {
            navigate('participants')
        }
    }, [isRoot, navigate])
}

const useShowOperationTranslation = createUseTranslation({
    FR: {
        operationNotFound: 'Opération non trouvée',
        participants: 'Participants',
        attachments: 'Documents KYC',
        documents: 'Documents',
        emails: 'Emails',
        fields: 'Champs actifs',
    },
    EN: {
        operationNotFound: 'Operation not found',
        participants: 'Participants',
        attachments: 'KYC Documents',
        documents: 'Documents',
        emails: 'Emails',
        fields: 'Fields',
    },
})

export const ShowOperation: FC = () => {
    useRedirectFromRootToParticipants()
    const { t } = useShowOperationTranslation()

    const { operationId } = useParams()

    assert.defined(operationId, 'Undefined operation ID')

    const { data } = useGetOperationSuspenseQuery({ variables: { operationId } })
    const operation = data?.operation

    const title = useMemo(() => {
        if (isNil(operation)) {
            return null
        }

        return (
            <OperationHeader operation={operation}>
                <Space>
                    <ExportOperation operation={operation} />
                    <EditOperation operation={operation} />
                    <OperationStatusControl operation={operation} />
                </Space>
            </OperationHeader>
        )
    }, [operation])

    const tabs = useTabs(
        useMemo((): Tab[] => {
            if (isNil(operation)) {
                return []
            }

            return [
                {
                    key: 'participants',
                    label: t('participants'),
                    content: <Participants operation={operation} />,
                    icon: <UserOutlined />,
                    matches: location => location.pathname.match(`${operationId}/participants`) !== null,
                },
                {
                    key: 'documents',
                    label: t('documents'),
                    content: <Documents operation={operation} />,
                    icon: <FileOutlined />,
                    matches: location => location.pathname.match(`${operationId}/documents`) !== null,
                },
                {
                    key: 'emails',
                    label: t('emails'),
                    content: <Emails operation={operation} />,
                    icon: <MailOutlined />,
                    matches: location => location.pathname.match(`${operationId}/emails`) !== null,
                },
                {
                    key: 'fields',
                    label: t('fields'),
                    content: <Fields operation={operation} />,
                    icon: <FormOutlined />,
                },
                {
                    key: 'attachments',
                    label: t('attachments'),
                    content: <Attachments operation={operation} />,
                    icon: <PaperClipOutlined />,
                },
            ]
        }, [operation, operationId, t])
    )

    if (isNil(operation)) {
        throw new NotFoundError(t('notFound'))
    }

    return (
        <Card title={title}>
            <Tabs
                defaultActiveKey="participants"
                activeKey={tabs.activeTab}
                onTabClick={tabs.onChange}
                items={tabs.items}
            />
        </Card>
    )
}
