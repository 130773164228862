import { Dropdown } from 'antd'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { documentFileDownloadMenuItemKeys, sortDocumentFilesByType } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'
import { assert, buildIdMap } from '@publica/utils'

import { DocumentActionsProps } from '../DocumentTable'
import { DownloadButton } from '../DownloadButton'
import { useDownloadArchive, useDownloadDocument } from '../hooks/downloadDocuments'

type DocumentFile = DocumentActionsProps['document']['files'][number]

type DocumentDownloadButtonProps = {
    operationId: string
    documentFiles: DocumentFile[]
    disabled?: boolean
}

export const DocumentDownloadButton: FC<DocumentDownloadButtonProps> = ({
    documentFiles,
    operationId,
    disabled = false,
}) => {
    if (disabled || documentFiles.length < 2) {
        return <DocumentDownloadSingleButton disabled={disabled} documentFile={documentFiles[0]} />
    }

    return <DocumentDownloadMultipleButton documentFiles={documentFiles} operationId={operationId} />
}

type DocumentDownloadSingleButtonProps = {
    documentFile?: DocumentFile
    disabled?: boolean
}

const DocumentDownloadSingleButton: FC<DocumentDownloadSingleButtonProps> = ({ documentFile, disabled = false }) => {
    const styles = utils.useActionsStyles()
    const [_, downloadDocument] = useDownloadDocument()

    const download = useCallback(() => {
        if (documentFile !== undefined) {
            downloadDocument(documentFile)
        }
    }, [documentFile, downloadDocument])

    return (
        <DownloadButton
            className={styles.actions}
            disabled={disabled || documentFile === undefined}
            onClick={download}
        />
    )
}

const useDownloadMultipleTranslation = createUseTranslation({
    FR: {
        certificate: 'Certificat',
        signed: 'Document signé',
        original: 'Document non-signé',
        all: 'Tous les documents',
    },
    EN: {
        certificate: 'Certificate',
        signed: 'Signed document',
        original: 'Unsigned document',
        all: 'All documents',
    },
})

type DocumentDownloadMultipleButtonProps = {
    operationId: string
    documentFiles: DocumentFile[]
}

const DocumentDownloadMultipleButton: FC<DocumentDownloadMultipleButtonProps> = ({ documentFiles, operationId }) => {
    const styles = utils.useActionsStyles()
    const buttonStyles = useDocumentDownloadMultipleButtonStyles()
    const { t } = useDownloadMultipleTranslation()

    const [name, items] = useMemo(() => {
        const [firstDocumentFile, ...otherDocumentFiles] = sortDocumentFilesByType(documentFiles)
        assert.defined(firstDocumentFile)

        const items = [
            ...[firstDocumentFile, ...otherDocumentFiles].map(file => ({
                key: file.id,
                label: (() => {
                    switch (file.type) {
                        case 'CERTIFICATE':
                            return t('certificate')
                        case 'SIGNED':
                            return t('signed')
                        case 'ORIGINAL':
                            return t('original')
                    }
                })(),
            })),
            { type: 'divider' } as const,
            { key: documentFileDownloadMenuItemKeys.ALL, label: t('all') },
        ]

        return [firstDocumentFile.name, items]
    }, [documentFiles, t])

    const documentFileIdMap = useMemo(() => buildIdMap(documentFiles), [documentFiles])

    const [downloadingArchive, downloadArchive, notificationContext] = useDownloadArchive({
        name,
        operationId,
        documentFileIds: documentFiles.map(documentFile => documentFile.id),
    })

    const [downloadingDocument, downloadDocument] = useDownloadDocument()

    const downloading = downloadingArchive || downloadingDocument

    const downloadFileFromMenu = useCallback(
        ({ key }: { key: string }) => {
            if (key === documentFileDownloadMenuItemKeys.ALL) {
                downloadArchive()
            } else {
                const documentFile = documentFileIdMap[key]
                assert.defined(documentFile)
                downloadDocument(documentFile)
            }
        },
        [documentFileIdMap, downloadArchive, downloadDocument]
    )

    const menu = useMemo(
        () => ({
            onClick: downloadFileFromMenu,
            items,
        }),
        [downloadFileFromMenu, items]
    )
    const downloadButton = useMemo(() => <DownloadButton className={styles.actions} />, [styles.actions])
    const buttonsRender = useCallback(() => [undefined, downloadButton], [downloadButton])

    return (
        <>
            {notificationContext}{' '}
            <Dropdown.Button
                className={buttonStyles.documentDownloadMultiple}
                buttonsRender={buttonsRender}
                menu={menu}
                disabled={downloading}
            />
        </>
    )
}

const useDocumentDownloadMultipleButtonStyles = createUseStyles({
    documentDownloadMultiple: { display: 'inline-flex', width: 'auto' },
})
