import { fingerprint } from '@publica/common'
import { LogRecord, LogTransport, Logger, OpenTelemetryTransport, consoleTransport } from '@publica/logger'
import { config } from '@publica/ui-common-utils'

class DeferredOpenTelemetryTransport implements LogTransport {
    private pendingLogRecords: LogRecord[]
    private openTelemetryTransport: OpenTelemetryTransport | undefined
    private openTelemetryTransportDisabled = false

    constructor() {
        void config.get().then(config => {
            if (config.trace) {
                this.openTelemetryTransport = new OpenTelemetryTransport(config.traceCollector)

                for (const record of this.pendingLogRecords) {
                    this.openTelemetryTransport.log(record)
                }

                logger.log('Remote logging is enabled')
            } else {
                this.openTelemetryTransportDisabled = true
                logger.log('Remote logging is disabled')
            }
        })

        this.pendingLogRecords = []
    }

    log(record: LogRecord): void {
        if (this.openTelemetryTransportDisabled) {
            return
        }

        if (this.openTelemetryTransport !== undefined) {
            this.openTelemetryTransport.log(record)
        } else {
            this.pendingLogRecords.push(record)
        }
    }
}

export const logger = new Logger({
    name: __APP__,
    transport: [consoleTransport, new DeferredOpenTelemetryTransport()],
    labels: {
        ...fingerprint,
    },
})

config.configEvents.subscribe({
    next: value => {
        if (value !== undefined) {
            logger.log('Configuration loaded', { payload: value.config })
        }
    },
})
