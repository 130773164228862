import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ModalForm, icons } from '@publica/ui-web-components'

import { GetAccountsDocument } from '../../../../../data'
import { useAddAccountForm } from './AddAccountForm'

export const AddAccountControl: FC = () => {
    const form = useAddAccountForm({
        refetchQueries: [GetAccountsDocument],
    })
    const { t } = useAddAccountControlTranslation()
    return <ModalForm form={form} icon={icons.Add} title={t('create')} />
}

const useAddAccountControlTranslation = createUseTranslation({
    FR: {
        create: 'Créer un compte',
    },
    EN: {
        create: 'Create account',
    },
})
