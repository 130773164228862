import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form } from 'antd'
import { useCallback } from 'react'

import { forwardUserErrors, withContext } from '@publica/ui-common-network'
import { FC } from '@publica/ui-common-utils'
import { AccountForm, AccountFormValues } from '@publica/ui-web-components'
import { ControlledForm, ControlledFormWithElement, useControlledForm } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useCheckAccountEmailLazyQuery, useCreateAccountsMutation } from '../../../../../../data'

type AddAccountFormProps = {
    form: ControlledForm<string | undefined, AddAccountFormValues>
}

const AddAccountForm: FC<AddAccountFormProps> = ({ form }) => {
    const [checkAccountEmailQuery] = useCheckAccountEmailLazyQuery()

    const checkAccountEmailIsUnique = useCallback(
        async (email: string) => {
            const { data } = await checkAccountEmailQuery({
                variables: {
                    email,
                },
            })
            return data?.accountByEmail?.id === undefined
        },
        [checkAccountEmailQuery]
    )

    return (
        <Form form={form.form} layout="vertical">
            <AccountForm checkEmailIsUnique={checkAccountEmailIsUnique} requirePhoneNumber={false} />
        </Form>
    )
}

type AddAccountFormValues = AccountFormValues

type AddAccountFormOptions = {
    refetchQueries?: InternalRefetchQueriesInclude
}

export const useAddAccountForm = (
    options?: AddAccountFormOptions
): ControlledFormWithElement<string | undefined, AddAccountFormValues> => {
    const [createAccountsMutation, { loading }] = useCreateAccountsMutation()

    const submit = useCallback(
        async (values: AddAccountFormValues) => {
            const { phoneNumber, title, ...accountValues } = values

            assert.defined(title)

            return createAccountsMutation({
                variables: {
                    accounts: [
                        {
                            ...accountValues,
                            title,
                            phoneNumber: phoneNumber?.validNumber,
                        },
                    ],
                },
                context: withContext({
                    shouldForwardError: forwardUserErrors(['ALREADY_IN_USE']),
                }),
                refetchQueries: options?.refetchQueries,
            }).then(({ data }) => data?.createAccounts?.[0]?.id)
        },
        [options?.refetchQueries, createAccountsMutation]
    )

    return useControlledForm({
        render: form => <AddAccountForm form={form} />,
        submit,
        loading,
    })
}
