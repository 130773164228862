/* istanbul ignore file */
import isFunction from 'lodash/isFunction'

import { RenderContext, type RenderInstruction, renderValue } from '@publica/render'
import { isPromise } from '@publica/utils'
import { DateValue, FloatValue, LookupValue, MapValue, TextValue, Value } from '@publica/values'

import { type ValueFieldInstance, valueFieldInstanceParametersSchema } from './valueFieldInstance'

export function renderValueFieldInstanceWithValue(
    fieldInstance: ValueFieldInstance,
    valueMap: Record<string, Value>,
    context: RenderContext['context']
): string
export function renderValueFieldInstanceWithValue(
    fieldInstance: ValueFieldInstance,
    valueMap: Record<string, Value>,
    context: RenderContext['context'],
    allowUndefined: true
): string | undefined
export function renderValueFieldInstanceWithValue<V extends Value | Promise<Value>>(
    fieldInstance: ValueFieldInstance,
    valueFn: (key: string) => V,
    context: RenderContext['context']
): V extends Promise<V> ? Promise<string> : string
export function renderValueFieldInstanceWithValue(
    fieldInstance: ValueFieldInstance,
    valueMapOrFn: Record<string, Value> | ((key: string) => Value) | ((key: string) => Promise<Value>),
    context: RenderContext['context'],
    allowUndefined = false
): string | Promise<string> | undefined {
    const renderFn = (value: Value): string => {
        const instruction = createRenderInstruction(fieldInstance, value, context)
        return renderValue(instruction)
    }

    if (!isFunction(valueMapOrFn)) {
        const value = valueMapOrFn[fieldInstance.key]

        if (value === undefined) {
            if (allowUndefined) {
                return undefined
            }
            throw new Error(`Unable to find value for field instance with key: ${fieldInstance.key}`)
        }

        return renderFn(value)
    }

    const value = valueMapOrFn(fieldInstance.key)

    if (isPromise(value)) {
        return value.then(renderFn)
    } else {
        return renderFn(value)
    }
}

const createRenderInstruction = (
    fieldInstance: ValueFieldInstance,
    value: Value,
    context: RenderContext['context']
): RenderInstruction => {
    const params = valueFieldInstanceParametersSchema.parse(fieldInstance.params)

    switch (params.type) {
        case 'text':
            throwIfNotOfType<TextValue>(fieldInstance.key, value, 'TextValue')
            return {
                ...value,
                context,
            }
        case 'date':
            throwIfNotOfType<DateValue>(fieldInstance.key, value, 'DateValue')
            return {
                ...value,
                options: params,
                context,
            }
        case 'lookup':
            throwIfNotOfType<LookupValue>(fieldInstance.key, value, 'LookupValue')
            return {
                ...value,
                options: params,
                context,
            }
        case 'float':
            throwIfNotOfType<FloatValue>(fieldInstance.key, value, 'FloatValue')
            return {
                ...value,
                options: params,
                context,
            }
        case 'map':
            throwIfNotOfType<MapValue>(fieldInstance.key, value, 'MapValue')
            return {
                ...value,
                options: params,
                context,
            }
    }
}

function throwIfNotOfType<V extends Value>(key: string, value: Value, expectedType: V['type']): asserts value is V {
    if (value.type !== expectedType) {
        throw new Error(`Expected type ${expectedType} for ${key}, got ${value.type}`)
    }
}
