import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'

import { useUnarchiveEmailSetsMutation } from '../../../../../../../data'
import { EmailSet } from '../../../types'

const useEmailSetRestoreControlTranslation = createUseTranslation({
    FR: {
        restore: 'Rétablir',
    },
    EN: {
        restore: 'Restore',
    },
})

type EmailSetRestoreControlProps = {
    emailSet: EmailSet
}

export const EmailSetRestoreControl: FC<EmailSetRestoreControlProps> = ({ emailSet }) => {
    const { t } = useEmailSetRestoreControlTranslation()
    const [unarchiveEmailSetsMutation, { loading }] = useUnarchiveEmailSetsMutation()
    const enabled = !loading

    const onClick = useAsyncCallback(async () => {
        await unarchiveEmailSetsMutation({
            variables: {
                ids: [emailSet.id],
            },
        })
    }, [emailSet.id, unarchiveEmailSetsMutation])

    return (
        <ActionButton disabled={!enabled} onClick={onClick}>
            {t('restore')}
        </ActionButton>
    )
}
