import { DateTime } from 'luxon'
import naturalSortFactory from 'natural-sort'

export const naturalSort = naturalSortFactory()

export const naturalSortBy = <I>(seq: I[], fn: (i: I) => string | number): I[] => {
    return seq.slice().sort((a, b) => naturalSort(fn(a), fn(b)))
}

export const sortByDateTime = <R extends Record<K, DateTime>, K extends keyof R>(items: R[], key: K) =>
    [...items].sort((a, b) => a[key].toMillis() - b[key].toMillis())
