import { InMemoryCache } from '@apollo/client'
import 'react-querybuilder/dist/query-builder.scss'

import '@publica/ui-common-polyfill'
import { boot } from '@publica/ui-web-boot'

import App from './app/app'
import { Login, authStateBuilder } from './auth'
import { possibleTypes } from './data/fragments.generated.json'
import './styles.less'

boot(<App />, {
    auth: {
        stateBuilder: authStateBuilder,
        login: <Login />,
    },
    apollo: {
        cache: new InMemoryCache({
            typePolicies: {
                Operation: {
                    fields: {
                        participantsConnection: {
                            keyArgs: false,
                        },
                        documentSets: {
                            keyArgs: false,
                        },
                        emailSets: {
                            keyArgs: false,
                        },
                    },
                },
                Query: {
                    queryType: true,
                    fields: {
                        accounts: {
                            keyArgs: false,
                        },
                    },
                },
            },
            possibleTypes,
        }),
    },
})
